<template>
  <div class="car-list-container pt-5">
    <div class="container w-75 mx-auto">
     <ul class="breadcrumb">
        <li>
          <router-link to="/" class="main">{{ $t('Home') }}</router-link> /

          <span to="/dashboard" class="active">{{ $t('FQs') }}</span>
        </li>
      </ul>


      
    <header class=" d-flex justify-content-center align-items-center">
        <h2 class="cat-title fw-bold my-cars-title fs-2">{{ $t('FQs') }}</h2>
    </header>



    <Accordion :activeIndex="0">
        <AccordionTab v-for="faq in faqs" :key="faq.id" :header="faq.question">
            <p class="m-0 text-start">
               {{faq.answer}}
            </p>
        </AccordionTab>
        
    </Accordion>
    </div>
  </div>

</template>

<script >
import axios from "axios";
import Accordion from 'primevue/accordion';
import AccordionTab from 'primevue/accordiontab';
export default{
    data(){
        return{
            faqs : []
        }
    },
    components:{
Accordion,
AccordionTab,
    },
    methods:{
       
        async getFaqs(){
            await axios('fqss')
            .then( (res)=>{
                this.faqs = res.data.data ;
            } )
        },
    },
    mounted(){
        this.getFaqs();
    }
}
</script>


<style  lang="scss">
.p-accordion-header-link{
 background: #F3F4F6;
    border-radius: 0px;
    color: #322E2D;
        display: flex;
    justify-content: space-between;
    flex-direction: row-reverse;
    padding: 0 20px;
    height: 50px !important;
}
   
   .p-accordion-tab-active .p-accordion-header-link{
        background: #331F8E;
            color: #fff;

   }
.p-accordion-header-link{
    height: 40px;
}
.p-accordion-content{padding: 10px;}
.p-accordion-tab{
  margin-bottom: 13px;
}
  .apexcharts-legend{display: none !important;}
  .parts{
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    .item{
      width: 200px;
      .icon{
        width: 20px;
        height: 20px;
      }
      .text{
        font-weight: 500;
      }
    }
  }
</style>

<style scoped lang="scss">
.card-filter{
    border-radius: 10px;
    border: 1px solid rgba(219, 217, 217, 0.87);
    background:  #FFF;

}
.line{
    width: 50px;
    background-color: #331F8E;
    height: 3px;
    margin-top: -10px;
    &.right{
        margin-right: 15px;
        
    }
    &.left{
        margin-left: 15px;
    }
}
.detail{
color:  #331F8E;
font-family: Poppins;
font-size: 14px;
font-style: normal;
font-weight: 600;
line-height: 109.5%;

}
.car-model{
    color:  #322E2D;
font-family: Poppins;
font-size: 20px;
font-style: normal;
font-weight: 600;
line-height: normal;
text-align: start;
}
.cat-title{
color: #331F8E;
font-family: Poppins;
font-size: 20px;
font-style: normal;
font-weight: 700;
line-height: normal;
}
.car-list-container {
  padding: 5px;
  background-color: #f3f3ff;
}

header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

header h1 {
  color: #4d2e90;

}

 nav ul {
  display: flex;
  list-style: none;
  gap: 10px;
}

 nav ul li {
  font-size: 1.2em;
}

.car-list {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 20px;
  margin-top: 10px;
}

.car-item {
    height: 230px;
  background-color: white;
  padding: 10px;
  border-radius: 10px;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
  display: flex;
  gap: 20px;
}

.car-item img {
  width: 150px;
  height: 200px;
  border-radius: 10px;
}

.car-info {
  display: flex;
  flex-direction: column;
  gap: 10px;
  width: 50%;
}



.car-info ul {
  list-style: none;
  padding: 0;
}

.car-info ul li {
  font-size: 1em;
  line-height: 1.5;
}

footer {
  display: flex;
  justify-content: center;
  margin-top: 20px;
}
</style>
