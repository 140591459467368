<template>
    <faqComponent
          data-aos="fade-up" data-aos-duration="2000"
    />
   

  <downloadApp
    data-aos="fade-up" data-aos-duration="2000"
   />
</template>

<script>
import faqComponent from "@/components/layout/faqsComponent.vue";
import AOS from "aos";
import downloadApp from "@/components/layout/downloadApp.vue"

export default{
  mounted() {
    AOS.init();
  },
  components:{
faqComponent,
downloadApp
  }
}
</script>
