export default {
  "Home": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Home"])},
  "About us": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["About us"])},
  "Services": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Services"])},
  "Available cars": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Available cars"])},
  "FAQ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["FAQS"])},
  "Privacy Policy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Privacy Policy"])},
  "Dashboard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dashboard"])},
  "My Cars": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["My Cars"])},
  "Ledger": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ledger"])},
  "Subcustomers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Subcustomers"])},
  "My Profile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["My Profile"])},
  "Shipping Price List": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Shipping Price List"])},
  "Search by Vin or LOT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Search by Vin or LOT"])},
  "Contact us": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contact us"])},
  "Logout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Logout"])},
  "hero_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["INTERNATIONAL AUTO SHIPPERS we will Ship your autos throughout the USA"])},
  "hero_desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["From any U.S. city to global destinations, we provide vehicle shipping and nationwide auto auction access with peace of mind We transport your vehicle, motorcycle, boat, or heavy machinery from auctions and dealerships to our shipping terminals."])},
  "GO WITH AN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["GO WITH AN"])},
  "INDUSTRY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["INDUSTRY"])},
  "LEADER YOU": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["LEADER YOU"])},
  "CAN TRUST": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CAN TRUST"])},
  "info_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Noor Alawahdah will share the growth and experince with you, Are You Ready For That ! "])},
  "info_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" As we have five shipping terminals for efficient receiving, loading, and shipping of cargo overseas."])},
  "info_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["WE HAVE ONE GOAL: MAKING YOU MORE SUCCESSFUL That is why everything we do empowers you to max your potential with options to wholesale your way and make decisions confidently without surprises. From end-to-end, we have everything you will ever need along the way, because at Noor Alwahdah, our mission is yours."])},
  "Our Services": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Our Services"])},
  "What you get with logistics business": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["What you get with logistics business"])},
  "What we can do for you and your company?": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["What we can do for you and your company?"])},
  "Read More": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Read More"])},
  "Download the application for more ease": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Download the application for more ease"])},
  "download_desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" Download the application for all types of mobile devices and tablets. You can download the application easily from the Play Store for Android devices, or from the App Store for IOS devices and enjoy a distinctive and easier experience in purchasing and tracking orders."])},
  "Frequently Questions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Frequently Questions"])},
  "Latest Cars": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Latest Cars"])},
  "Useful Links": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Useful Links"])},
  "Contact Information": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contact Information"])},
  "Address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Address"])},
  "Phone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Phone"])},
  "Email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email"])},
  "WhatsApp": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["WhatsApp"])},
  "Copyright 2024. All Rights Reserved - Noor Al Wahda": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Copyright 2024. All Rights Reserved - Noor Al Wahda"])},
  "Powered by Noor Al Wahda": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Powered by Noor Al Wahda"])},
  "No Images Provided": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No Images Provided"])},
  "Change Your Password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Change Your Password"])},
  "Please Enter  Information": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please Enter  Information"])},
  "Old Password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Old Password"])},
  "Please enter  password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please enter  password"])},
  "New Password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["New Password"])},
  "Confirm Password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirm Password"])},
  "Password identically": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Password identically"])},
  "Password Doesnot Match": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Password Doesnot Match"])},
  "Change": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Change"])},
  "Please Enter Your Information": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please Enter Your Information"])},
  "Your Name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your Name"])},
  "Please enter your name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please enter your name"])},
  "Mobile Number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mobile Number"])},
  "Please enter your Mobile Number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please enter your Mobile Number"])},
  "Please enter your email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please enter your email"])},
  "Message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Message"])},
  "Please Enter Yout Message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please Enter Yout Message"])},
  "Send": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Send"])},
  "Hello Dear Visitor!": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hello Dear Visitor!"])},
  "Please Enter Login Information": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please Enter Login Information"])},
  "Customer Phone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Customer Phone"])},
  "Please enter your customer Phone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please enter your customer Phone"])},
  "Password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Password"])},
  "Please enter your password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please enter your password"])},
  "Forget Password ? ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Forget Password ? "])},
  "Login": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Login"])},
  "OR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["OR"])},
  "Request A User": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Request A User"])},
  "Please contact customer service at email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please contact customer service at email"])},
  "Profile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Profile"])},
  "You Can Edit Your Information From Here": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You Can Edit Your Information From Here"])},
  "Customer Name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Customer Name"])},
  "Please enter your username": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please enter your username"])},
  "Customer Email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Customer Email"])},
  "Customer Country": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Customer Country"])},
  "Select a Country": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select a Country"])},
  "Edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit"])},
  "Change Password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Change Password"])},
  "Sent successfully ;": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sent successfully ;"])},
  "Our team will contact you soon": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Our team will contact you soon"])},
  "Please Enter Register Information": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please Enter Register Information"])},
  "Customer Password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Customer Password"])},
  "Request": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Request"])},
  "My Accounts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["My Accounts"])},
  "SubCustomers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SubCustomers"])},
  "Add": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add"])},
  "Add Sub customer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add Sub customer"])},
  "Please enter Customer information": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please enter Customer information"])},
  "Please enter your customer name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please enter your customer name"])},
  "Customer Address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Customer Address"])},
  "Edit Sub customer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit Sub customer"])},
  "show": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["show"])},
  "SubCustomer Details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SubCustomer Details"])},
  "Assigned Cars": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Assigned Cars"])},
  "Child Customer ID": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Child Customer ID"])},
  "Child Customer Name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Child Customer Name"])},
  "Phone Number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Phone Number"])},
  "Block Reason": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Block Reason"])},
  "Block": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Block"])},
  "UnBlock": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["UnBlock"])},
  "No Assigend Cars for this user Yet !": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No Assigend Cars for this user Yet !"])},
  "Reason": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reason"])},
  "Confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirm"])},
  "Add SubCustomer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add SubCustomer"])},
  "Customer ID": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Customer ID"])},
  "No SubCustomer Yet !": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No SubCustomer Yet !"])},
  "Pirce": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pirce"])},
  "Car ID": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Car ID"])},
  "Vin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vin"])},
  "Assign To": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Assign To"])},
  "Car Details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Car Details"])},
  "LOT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["LOT"])},
  "Lot": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lot"])},
  "Car Status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Car Status"])},
  "Auction location": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Auction location"])},
  "Auction Name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Auction Name"])},
  "Purchasing Date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Purchasing Date"])},
  "Car Amount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Car Amount"])},
  "Costs Amount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Costs Amount"])},
  "Paid Amount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Paid Amount"])},
  "Paid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Paid"])},
  "Total Costs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total Costs"])},
  "Customer Outstanding": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Customer Outstanding"])},
  "Car Tracker": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Car Tracker"])},
  "Car Finance": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Car Finance"])},
  "Gallery": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gallery"])},
  "Current Step": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Current Step"])},
  "Car Mark": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Car Mark"])},
  "Car Model": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Car Model"])},
  "Year": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Year"])},
  "Transmission": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transmission"])},
  "Fuel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fuel"])},
  "Color": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Color"])},
  "Body Type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Body Type"])},
  "Odometer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Odometer"])},
  "Engine Type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Engine Type"])},
  "Cylinders": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cylinders"])},
  "Key": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Key"])},
  "Primary Damage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Primary Damage"])},
  "Container": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Container"])},
  "Estimation Arrival Date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Estimation Arrival Date"])},
  "Picked Up Location": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Picked Up Location"])},
  "Start Date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Start Date"])},
  "End Date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["End Date"])},
  "New Cars": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["New Cars"])},
  "Total Cars": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total Cars"])},
  "Towing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Towing"])},
  "WareHouses": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["WareHouses"])},
  "Shipping": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Shipping"])},
  "Custom": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Custom"])},
  "Ready Collected": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ready Collected"])},
  "No Cars Yet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No Cars Yet"])},
  "My Account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["My Account"])},
  "No Shipping Lists Yet !": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No Shipping Lists Yet !"])},
  "Search Result": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Search Result"])},
  "There is no data currently, search for cars to appear": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["There is no data currently, search for cars to appear"])},
  "Download": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Download"])},
  "No Services Yet !": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No Services Yet !"])},
  "comprehensive services": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["comprehensive services"])},
  "Auto Auction Access For The Best US Auto Auctions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Auto Auction Access For The Best US Auto Auctions"])},
  "Car Shipping": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Car Shipping"])},
  "Domestic Transportation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Domestic Transportation"])},
  "Car Selling": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Car Selling"])},
  "Do you want to ship the car?": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Do you want to ship the car?"])},
  "desc_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["We at Noor Alwahdah know well how to plan your business with providing you all your needs  At Noor Alwahdah community, we are keeping growing our GLOBAL LOGISTICS Experiences, Take your tour in our community, and Get in touch with us to give us the chance to share with you the experience. "])},
  "Ship": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ship"])},
  "Do you want to buy a car?": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Do you want to buy a car?"])},
  "desc_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Car purchasing services help customers find, finance, and buy vehicles with ease, offering a hassle-free experience from selection to ownership."])},
  "Buy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Buy"])},
  "Car Profile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Car Profile"])},
  "Lot No": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lot Number"])},
  "FQs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["FQs"])},
  "Get in Touch": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Get in Touch"])},
  "com_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["we provide exclusive dealer-level access to the largest salvage and used car market in the US. Our partnerships with leading auto  auction platforms enable both American and international buyers to search and bid on a wide range of vehicles and machinery with ease. Our multilingual team and reliable system ensure a seamless experience, handling all necessary documentation, export requirements, and customs clearance. Partner with us to gain a competitive edge in the US auto auction market and maximize your success."])},
  "Shipping your autos throughout the USA": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Shipping your autos throughout the USA"])},
  "com_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["We transport your vehicle, motorcycle, boat, or heavy machinery from auctions and dealerships to our shipping terminals. We deliver DEPENDING ON TIMING, CARGO SIZE, AND EQUIPMENT NEEDS, WE PROVIDE"])},
  "OUR NETWORK OF US AUTO AUCTIONS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["OUR NETWORK OF US AUTO AUCTIONS"])},
  "How Does It Work ?": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["How Does It Work ?"])},
  "ser_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["we provide exclusive dealer-level access to the largest salvage and used car market in the US. Our partnerships with leading auto auction platforms enable both American and international buyers to search and bid on a wide range of vehicles and machinery with ease."])},
  "READ MORE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["READ MORE"])},
  "ser_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["We transport your vehicle, motorcycle, boat, or heavy machinery from auctions and dealerships to our shipping terminals. "])},
  "Terms And Conditions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Terms And Conditions"])},
  "Auction": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Auction"])},
  "Price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Price"])},
  "Faqs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["FQs"])},
  "You Can See All Notifications Here": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You Can See All Notifications Here"])},
  "Contact Us": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contact Us"])},
  "Avilable Cars": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Avilable Cars"])},
  "Sub-Customer Management Service by Noor Alwahdah": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sub-Customer Management Service by Noor Alwahdah"])},
  "sub_4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["At Noor Alwahdah, we are always looking for ways to enhance the experience of our customers and streamline the shipping process. As part of our comprehensive service offering, we provide a powerful *Sub-Customer Management System* that allows our primary customers to create accounts for their own sub-customers."])},
  "sub_5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["At Noor Alwahdah, we are always looking for ways to enhance the experience of our customers and streamline the shipping process. As part of our comprehensive service offering, we provide a powerful *Sub-Customer Management System* that allows our primary customers to create accounts for their own sub-customers. This feature gives you more control and flexibility, enabling you to offer a tailored experience for your clients while using our platform."])},
  "With our state-of-the-art web and mobile applications, you can:": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["With our state-of-the-art web and mobile applications, you can:"])},
  "Create and manage sub-customer accounts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create and manage sub-customer accounts"])},
  "- Easily generate user profiles for your clients and assign them roles and permissions to access our system.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["- Easily generate user profiles for your clients and assign them roles and permissions to access our system."])},
  "ser_4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This system is designed to empower you and your sub-customers, creating a seamless, transparent, and efficient shipping experience. Whether you're managing a large portfolio of clients or simply offering additional services to a select few, our *Sub-Customer Management Service* ensures that everyone enjoys the same level of excellence and professionalism Noor Alwahdah is known for."])},
  "Grant access to the platform": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Grant access to the platform"])},
  "- Allow your sub-customers to track their own shipments, manage their finances, and view auction statuses directly from the application or website.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["- Allow your sub-customers to track their own shipments, manage their finances, and view auction statuses directly from the application or website."])},
  "Real-time tracking": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Real-time tracking"])},
  "Provide your sub-customers with real-time visibility of their shipments, allowing them to monitor every stage of the process from auction to delivery.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Provide your sub-customers with real-time visibility of their shipments, allowing them to monitor every stage of the process from auction to delivery."])},
  "Transparent financial management": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transparent financial management"])},
  "Customized notifications": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Customized notifications"])},
  "- Keep your sub-customers informed at every step with customized alerts and notifications tailored to their needs.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["- Keep your sub-customers informed at every step with customized alerts and notifications tailored to their needs."])}
}