<template>
  <div class="car-list-container pt-5">
    <div class="container w-75 mx-auto">
      <ul class="breadcrumb">
        <li>
          <router-link to="/" class="main">{{ $t('Home') }}</router-link> /

          <span to="/dashboard" class="active">{{ $t('Services') }}</span>
        </li>
      </ul>

      <div class="details-head">
        <div class="row align-items-center">
          <div class="col-md-6 mb-3">
            <section class="details-head-images d-flex">
              <div class="details-left">
                <img src="@/assets/imgs/mac.png" alt="" />
              </div>

              <div class="details-right">
                <img src="@/assets/imgs/hand.png" alt="" />
              </div>
            </section>
          </div>

          <div class="col-md-6">
            <header class="d-flex justify-content-end align-items-center mx-4">
              <span class="line right"></span>
              <h3
                class="cat-title fw-bold my-cars-title"
                v-if="$route.params.id == 1"
              >
                {{ $t('Comprehensive Services') }}
              </h3>
              <h3
                class="cat-title fw-bold my-cars-title"
                v-if="$route.params.id == 2"
              >
                {{ $t('Car Shipping') }}
              </h3>
              <h3
                class="cat-title fw-bold my-cars-title"
                v-if="$route.params.id == 4"
              >
                {{ $t('Subcustomers') }}
              </h3>
              <span class="line left"></span>
            </header>
          </div>
        </div>
      </div>

      <section class="mt-4">
        <div class="row">
          <div class="col-md-6">
            <p class="text-start fs-5" v-if="$route.params.id == 1">
              {{ $t('com_1') }}
            </p>
            <p class="text-start fs-5" v-if="$route.params.id == 2">
              <strong>{{ $t('Shipping your autos throughout the USA') }}</strong>
              , 
              {{ $t('com_2') }}
            </p>
            <p class="text-start fs-5" v-if="$route.params.id == 4">
              <strong>{{ $t('Sub-Customer Management Service by Noor Alwahdah') }}</strong>
              , 
              {{ $t('sub_5') }}
            </p>
          </div>

          <div class="col-md-6">
            <div class="service__image mx-5">
              <img src="@/assets/imgs/ser1.png" alt="" />
            </div>
          </div>
        </div>

        <h3 class="fw-bold text-start mt-4"  v-if="$route.params.id == 1">{{$t('OUR NETWORK OF US AUTO AUCTIONS')}}</h3>
        <h3 class="fw-bold text-start mt-4"  v-if="$route.params.id == 2">{{ $t('How Does It Work ?') }}</h3>
        <h3 class="fw-bold text-start mt-4"  v-if="$route.params.id == 4">{{ $t('With our state-of-the-art web and mobile applications, you can:') }}</h3>


        <section v-if="$route.params.id == 4">
          <p>
            <strong>{{$t('Create and manage sub-customer accounts') }}</strong> :
            {{  $t('- Easily generate user profiles for your clients and assign them roles and permissions to access our system.')  }}
          </p>
          <p>
            <strong>{{$t('Grant access to the platform') }}</strong> :
            {{  $t('- Allow your sub-customers to track their own shipments, manage their finances, and view auction statuses directly from the application or website.')  }}
          </p>
          <p>
            <strong>{{$t('Real-time tracking') }}</strong> :
            {{  $t('Provide your sub-customers with real-time visibility of their shipments, allowing them to monitor every stage of the process from auction to delivery.')  }}
          </p>
          <p>
            <strong>{{$t('Transparent financial management') }}</strong> :
            {{  $t('- Sub-customers can manage their billing, view invoices, and handle payments, providing them with a clear and organized overview of their transactions.')  }}
          </p>
          <p>
            <strong>{{$t('Customized notifications') }}</strong> :
            {{  $t('- Keep your sub-customers informed at every step with customized alerts and notifications tailored to their needs.')  }}
          </p>

          <div class="mt-5">
            {{  $t('ser_4')  }}
          </div>
        </section>
        <Carousel
          :autoplay="100"
          transition="2000"
          :items-to-show="4"
          :wrap-around="true"
          :breakpoints="breakpoints"
          v-if="$route.params.id == 1"
        >
          <Slide v-for="(slide, index) in images" :key="index">
            <img :src="slide" class="service_slider" alt="" />
          </Slide>
        </Carousel>

        <!-- car shipping  -->
        <div v-if="$route.params.id == 2">
          <div class="car-shipping-image">
            <img src="@/assets/imgs/car-shipping.png" alt="">
          </div>
        </div>

        <getInTouch />
      </section>
    </div>
  </div>
</template>

<script>
import { Carousel, Slide } from "vue3-carousel";

import getInTouch from "./getInTouch.vue";

import a from "@/assets/imgs/a.png";
import b from "@/assets/imgs/b.png";
import c from "@/assets/imgs/c.png";
import d from "@/assets/imgs/d.png";
import e from "@/assets/imgs/e.png";
import f from "@/assets/imgs/f.png";
import g from "@/assets/imgs/g.png";
import h from "@/assets/imgs/h.png";
export default {
  data() {
    return {
      data: "",
      faqs: [],
      images: [a, b, c, d, e, f, g, h],
    };
  },
  components: {
    getInTouch,
    Carousel,
    Slide,
  },
};
</script>

<style lang="scss" scoped>
.service_slider {
  width: 160px;
  height: 100px;
}
.service__image {
  img {
    width: 32vw;
  }
}
.my-cars-title {
  color: #efcf2d;
}
.line {
  width: 50px;
  background-color: #efcf2d;
  height: 3px;
  margin-top: -10px;
  &.right {
    margin-right: 15px;
  }
  &.left {
    margin-left: 15px;
  }
}

.details-left img {
  width: 29vw;
  height: 236px;
  margin: 15px;
  transform: translate(-2%, 11%);
}
.details-right {
  position: absolute;
  bottom: 0;
  left: 12%;
  img {
    width: 48%;
  }
}
.details-head {
  position: relative;
  background-color: #331f8e;
}
.car-title {
  color: #efcf2d !important;
}
</style>
