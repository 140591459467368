<template>
    <carDetailsGuest
          data-aos="fade-up" data-aos-duration="2000"
    />
   

 
</template>

<script>
import carDetailsGuest from "@/components/layout/carDetailsGuest.vue";
import AOS from "aos";

export default{
  mounted() {
    AOS.init();
  },
  components:{
carDetailsGuest,
  }
}
</script>
