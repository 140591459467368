export default {
  "Home": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الصفحة الرئيسية"])},
  "About us": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["من نحن"])},
  "Services": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الخدمات"])},
  "Available cars": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["السيارات المتاحة"])},
  "FAQ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الأسئلة الشائعة"])},
  "Privacy Policy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["سياسة الخصوصية"])},
  "Dashboard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["لوحة التحكم"])},
  "My Cars": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["سياراتي"])},
  "Ledger": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["دفتر الحسابات"])},
  "Subcustomers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["العملاء الفرعيون"])},
  "My Profile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ملفي الشخصي"])},
  "Shipping Price List": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["قائمة أسعار الشحن"])},
  "Search by Vin or LOT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["البحث بواسطة VIN أو LOT"])},
  "Contact us": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اتصل بنا"])},
  "Logout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تسجيل الخروج"])},
  "hero_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["شاحنو السيارات الدولية - سنقوم بشحن سيارتك في جميع أنحاء الولايات المتحدة"])},
  "hero_desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["من أي مدينة أمريكية إلى الوجهات العالمية، نقدم خدمة شحن المركبات والوصول إلى مزادات السيارات الوطنية براحة بال. نقوم بنقل مركبتك أو دراجتك النارية أو قاربتك أو آلاتك الثقيلة من المزادات والوكلاء إلى محطات الشحن الخاصة بنا."])},
  "GO WITH AN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اختر"])},
  "INDUSTRY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["صناعة"])},
  "LEADER YOU": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["رائدة يمكنك"])},
  "CAN TRUST": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الثقة بها"])},
  "info_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["نور الوحده ستشاركك النمو والخبرة، هل أنت مستعد لذلك؟"])},
  "info_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["لدينا خمسة محطات شحن لاستقبال وتحميل وشحن البضائع بكفاءة إلى الخارج."])},
  "info_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["لدينا هدف واحد: جعلك أكثر نجاحاً. لذلك، كل ما نقوم به يعزز إمكاناتك بأقصى حد من خلال خيارات البيع بالجملة وتوفير الثقة لاتخاذ قراراتك دون مفاجآت. لدينا كل ما تحتاجه على طول الطريق، لأن في نور الوحده، مهمتنا هي مهمتك."])},
  "Our Services": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["خدماتنا"])},
  "What you get with logistics business": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ما ستحصل عليه مع خدمات اللوجستيات"])},
  "What we can do for you and your company?": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ماذا يمكننا أن نقدم لك ولشركتك؟"])},
  "Read More": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اقرأ المزيد"])},
  "Download the application for more ease": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["قم بتنزيل التطبيق لمزيد من السهولة"])},
  "download_desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["قم بتنزيل التطبيق لجميع أنواع الهواتف المحمولة والأجهزة اللوحية. يمكنك تنزيل التطبيق بسهولة من متجر Play لأجهزة Android، أو من App Store لأجهزة IOS والاستمتاع بتجربة مميزة وأسهل في الشراء وتتبع الطلبات."])},
  "Frequently Questions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الأسئلة المتكررة"])},
  "Latest Cars": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["أحدث السيارات"])},
  "Useful Links": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["روابط مفيدة"])},
  "Contact Information": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["معلومات الاتصال"])},
  "Address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["العنوان"])},
  "Phone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الهاتف"])},
  "Email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["البريد الإلكتروني"])},
  "WhatsApp": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["واتساب"])},
  "Copyright 2024. All Rights Reserved - Noor Al Wahda": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["حقوق الطبع والنشر 2024. جميع الحقوق محفوظة - نور الوحده"])},
  "Powered by Noor Al Wahda": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["بدعم من نور الوحده"])},
  "No Images Provided": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["لم يتم توفير صور"])},
  "Change Your Password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تغيير كلمة المرور"])},
  "Please Enter Information": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["يرجى إدخال المعلومات"])},
  "Old Password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["كلمة المرور القديمة"])},
  "Please enter password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["يرجى إدخال كلمة المرور"])},
  "New Password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["كلمة المرور الجديدة"])},
  "Confirm Password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تأكيد كلمة المرور"])},
  "Password identically": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["كلمة المرور متطابقة"])},
  "Password Doesnot Match": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["كلمة المرور غير متطابقة"])},
  "Change": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تغيير"])},
  "Please Enter Your Information": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["يرجى إدخال معلوماتك"])},
  "Your Name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اسمك"])},
  "Please enter your name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["يرجى إدخال اسمك"])},
  "Mobile Number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["رقم الهاتف المحمول"])},
  "Please enter your Mobile Number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["يرجى إدخال رقم هاتفك المحمول"])},
  "Please enter your email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["يرجى إدخال بريدك الإلكتروني"])},
  "Message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الرسالة"])},
  "Please Enter Yout Message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["يرجى إدخال رسالتك"])},
  "Send": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["إرسال"])},
  "Hello Dear Visitor!": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["مرحباً عزيزي الزائر!"])},
  "Please Enter Login Information": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["يرجى إدخال معلومات الدخول"])},
  "Customer Phone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["هاتف العميل"])},
  "Please enter your customer Phone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["يرجى إدخال هاتف العميل"])},
  "Password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["كلمة المرور"])},
  "Please enter your password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["يرجى إدخال كلمة المرور"])},
  "Forget Password ?": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["نسيت كلمة المرور؟"])},
  "Login": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تسجيل الدخول"])},
  "OR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["أو"])},
  "Request A User": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["طلب مستخدم"])},
  "Please contact customer service at email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["يرجى الاتصال بخدمة العملاء على البريد الإلكتروني"])},
  "Profile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الملف الشخصي"])},
  "You Can Edit Your Information From Here": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["يمكنك تعديل معلوماتك من هنا"])},
  "Customer Name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اسم العميل"])},
  "Please enter your username": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["يرجى إدخال اسم المستخدم"])},
  "Customer Email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["البريد الإلكتروني للعميل"])},
  "Customer Country": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["دولة العميل"])},
  "Select a Country": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اختر دولة"])},
  "Edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تعديل"])},
  "Change Password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تغيير كلمة المرور"])},
  "Sent successfully ;": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تم الإرسال بنجاح؛"])},
  "Our team will contact you soon": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["سيتواصل معك فريقنا قريباً"])},
  "Please Enter Register Information": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["يرجى إدخال معلومات التسجيل"])},
  "Customer Password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["كلمة مرور العميل"])},
  "Request": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["طلب"])},
  "My Accounts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["حساباتي"])},
  "SubCustomers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["العملاء الفرعيون"])},
  "Add": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["إضافة"])},
  "Add Sub customer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["إضافة عميل فرعي"])},
  "Please enter Customer information": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["يرجى إدخال معلومات العميل"])},
  "Please enter your customer name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["يرجى إدخال اسم العميل"])},
  "Customer Address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["عنوان العميل"])},
  "Edit Sub customer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تعديل العميل الفرعي"])},
  "show": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["عرض"])},
  "SubCustomer Details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تفاصيل العميل الفرعي"])},
  "Assigned Cars": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["السيارات المعينة"])},
  "Child Customer ID": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["معرف العميل الفرعي"])},
  "Child Customer Name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اسم العميل الفرعي"])},
  "Phone Number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["رقم الهاتف"])},
  "Block Reason": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["سبب الحظر"])},
  "Block": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["حظر"])},
  "UnBlock": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["إلغاء الحظر"])},
  "No Assigend Cars for this user Yet !": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["لا توجد سيارات معينة لهذا المستخدم بعد!"])},
  "Reason": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["السبب"])},
  "Confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تأكيد"])},
  "Add SubCustomer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["إضافة عميل فرعي"])},
  "Customer ID": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["معرف العميل"])},
  "No SubCustomer Yet !": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["لا يوجد عملاء فرعيون بعد!"])},
  "Pirce": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["السعر"])},
  "Car ID": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["معرف السيارة"])},
  "Vin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["VIN"])},
  "Assign To": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تعيين إلى"])},
  "Car Details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تفاصيل السيارة"])},
  "LOT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["LOT"])},
  "Lot": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["LOT"])},
  "Car Status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["حالة السيارة"])},
  "Auction location": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["موقع المزاد"])},
  "Auction Name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اسم المزاد"])},
  "Purchasing Date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تاريخ الشراء"])},
  "Car Amount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["قيمة السيارة"])},
  "Costs Amount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["قيمة التكاليف"])},
  "Paid Amount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["المبلغ المدفوع"])},
  "Paid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["مدفوع"])},
  "Total Costs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["إجمالي التكاليف"])},
  "Customer Outstanding": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["المستحق للعميل"])},
  "Car Tracker": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["متتبع السيارة"])},
  "Car Finance": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تمويل السيارة"])},
  "Gallery": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["المعرض"])},
  "Current Step": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الخطوة الحالية"])},
  "Car Mark": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["علامة السيارة"])},
  "Car Model": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["طراز السيارة"])},
  "Year": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["السنة"])},
  "Transmission": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ناقل الحركة"])},
  "Fuel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الوقود"])},
  "Color": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اللون"])},
  "Body Type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["نوع الجسم"])},
  "Odometer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["عداد المسافات"])},
  "Engine Type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["نوع المحرك"])},
  "Cylinders": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الأسطوانات"])},
  "Key": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["المفتاح"])},
  "Primary Damage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الأضرار الرئيسية"])},
  "Container": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الحاوية"])},
  "Estimation Arrival Date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تاريخ الوصول المتوقع"])},
  "Picked Up Location": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["موقع الاستلام"])},
  "Start Date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تاريخ البدء"])},
  "End Date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تاريخ الانتهاء"])},
  "New Cars": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["السيارات الجديدة"])},
  "Total Cars": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["إجمالي السيارات"])},
  "Towing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["القطر"])},
  "WareHouses": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["المستودعات"])},
  "Shipping": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الشحن"])},
  "Custom": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الجمارك"])},
  "Ready Collected": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["جاهز للتجميع"])},
  "No Cars Yet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["لا توجد سيارات بعد"])},
  "My Account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["حسابي"])},
  "No Shipping Lists Yet !": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["لا توجد قوائم شحن حتى الآن!"])},
  "Search Result": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["نتيجة البحث"])},
  "There is no data currently, search for cars to appear": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["لا توجد بيانات حالياً، ابحث عن السيارات لتظهر."])},
  "Download": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تحميل"])},
  "No Services Yet !": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["لا توجد خدمات حتى الآن!"])},
  "comprehensive services": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["خدمات شاملة"])},
  "Auto Auction Access For The Best US Auto Auctions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الوصول إلى أفضل مزادات السيارات الأمريكية"])},
  "Car Shipping": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["شحن السيارات"])},
  "Domestic Transportation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["النقل المحلي"])},
  "Car Selling": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["بيع السيارات"])},
  "Do you want to ship the car?": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["هل تريد شحن السيارة؟"])},
  "desc_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["نحن في نور الواحدة نعرف جيداً كيفية تخطيط عملك من خلال توفير جميع احتياجاتك. في مجتمع نور الواحدة، نستمر في تطوير خبراتنا في اللوجستيات العالمية. قم بجولتك في مجتمعنا وتواصل معنا لمنحنا الفرصة لمشاركة التجربة معك."])},
  "Ship": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["شحن"])},
  "Do you want to buy a car?": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["هل تريد شراء سيارة؟"])},
  "desc_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["خدمات شراء السيارات تساعد العملاء في العثور على السيارات وتمويلها وشرائها بسهولة، وتوفر تجربة خالية من المتاعب من الاختيار إلى الملكية."])},
  "Buy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["شراء"])},
  "Car Profile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ملف السيارة"])},
  "Lot No": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["رقم الدفعة"])},
  "FQs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الأسئلة الشائعة"])},
  "Get in Touch": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اتصل بنا"])},
  "com_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["نحن نقدم وصولاً حصرياً على مستوى التجار إلى أكبر سوق للسيارات المستعملة والتالفة في الولايات المتحدة. شراكاتنا مع منصات مزادات السيارات الرائدة تتيح للمشترين الأمريكيين والدوليين البحث والمزايدة على مجموعة واسعة من السيارات والآلات بسهولة. يضمن فريقنا متعدد اللغات ونظامنا الموثوق تجربة سلسة، مع التعامل مع جميع الوثائق اللازمة ومتطلبات التصدير والتخليص الجمركي. اشترك معنا للحصول على ميزة تنافسية في سوق مزادات السيارات الأمريكية وزيادة نجاحك."])},
  "Shipping your autos throughout the USA": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["شحن سياراتك في جميع أنحاء الولايات المتحدة"])},
  "com_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["نحن نقوم بنقل سيارتك أو دراجتك النارية أو قاربك أو الآلات الثقيلة من المزادات والوكلاء إلى محطات الشحن لدينا. نحن نقدم بناءً على الوقت وحجم الحمولة واحتياجات المعدات."])},
  "OUR NETWORK OF US AUTO AUCTIONS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["شبكتنا من مزادات السيارات الأمريكية"])},
  "How Does It Work ?": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["كيف يعمل؟"])},
  "ser_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["نحن نقدم وصولاً حصرياً على مستوى التجار إلى أكبر سوق للسيارات المستعملة والتالفة في الولايات المتحدة. شراكاتنا مع منصات مزادات السيارات الرائدة تتيح للمشترين الأمريكيين والدوليين البحث والمزايدة على مجموعة واسعة من السيارات والآلات بسهولة."])},
  "READ MORE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اقرأ المزيد"])},
  "ser_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["نحن نقوم بنقل سيارتك أو دراجتك النارية أو قاربك أو الآلات الثقيلة من المزادات والوكلاء إلى محطات الشحن لدينا."])},
  "Terms And Conditions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الشروط والأحكام"])},
  "Auction": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["المزاد"])},
  "Price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["السعر"])},
  "Faqs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الاسئله الشائعة"])},
  "You Can See All Notifications Here": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["يمكنك الاطلاع علي جميع الاشعارات من هنا"])},
  "Contact Us": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تواصل معنا"])},
  "Avilable Cars": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["السيارات المتاحة"])},
  "Forget Password ? ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["هل نسيت كلمه المرور ؟"])},
  "Sub-Customer Management Service by Noor Alwahdah": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["خدمة إدارة العملاء الفرعيين من نور الوحدة"])},
  "sub_4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["في نور الوحدة، نعمل دائمًا على تحسين تجربة عملائنا وتبسيط عملية الشحن. كجزء من خدماتنا الشاملة، نقدم *نظام إدارة العملاء الفرعيين* الذي يتيح لعملائنا الأساسيين إنشاء حسابات لعملائهم الفرعيين. تمنحك هذه الميزة المزيد من التحكم والمرونة، مما يمكنك من تقديم تجربة مخصصة لعملائك أثناء استخدام منصتنا."])},
  "sub_5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["في نور الوحدة، نعمل دائمًا على تحسين تجربة عملائنا وتبسيط عملية الشحن. كجزء من خدماتنا الشاملة، نقدم *نظام إدارة العملاء الفرعيين* الذي يتيح لعملائنا الأساسيين إنشاء حسابات لعملائهم الفرعيين. تمنحك هذه الميزة المزيد من التحكم والمرونة، مما يمكنك من تقديم تجربة مخصصة لعملائك أثناء استخدام منصتنا."])},
  "With our state-of-the-art web and mobile applications, you can:": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["من خلال تطبيقات الويب والجوال المتطورة لدينا، يمكنك:"])},
  "Create and manage sub-customer accounts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["إنشاء وإدارة حسابات العملاء الفرعيين"])},
  "- Easily generate user profiles for your clients and assign them roles and permissions to access our system.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["- قم بإنشاء ملفات تعريف المستخدمين لعملائك بسهولة وامنحهم الأدوار والصلاحيات اللازمة للوصول إلى نظامنا."])},
  "ser_4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تم تصميم هذا النظام لتمكينك وتمكين عملائك الفرعيين، مما يوفر تجربة شحن سلسة وشفافة وفعالة. سواء كنت تدير مجموعة كبيرة من العملاء أو تقدم خدمات إضافية لعدد قليل مختار، فإن *خدمة إدارة العملاء الفرعيين* تضمن أن الجميع يستمتع بنفس مستوى التميز والاحترافية الذي تعرف به نور الوحدة."])},
  "Grant access to the platform": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["منح الوصول إلى المنصة"])},
  "- Allow your sub-customers to track their own shipments, manage their finances, and view auction statuses directly from the application or website.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["- اسمح لعملائك الفرعيين بتتبع شحناتهم الخاصة، وإدارة أمورهم المالية، وعرض حالات المزادات مباشرة من التطبيق أو الموقع الإلكتروني."])},
  "Real-time tracking": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تتبع في الوقت الفعلي"])},
  "Provide your sub-customers with real-time visibility of their shipments, allowing them to monitor every stage of the process from auction to delivery.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["امنح عملائك الفرعيين رؤية مباشرة لشحناتهم في الوقت الفعلي، مما يتيح لهم مراقبة كل مرحلة من العملية بدءاً من المزاد وحتى التسليم."])},
  "Transparent financial management": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["إدارة مالية شفافة"])},
  "Customized notifications": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["إشعارات مخصصة"])},
  "- Keep your sub-customers informed at every step with customized alerts and notifications tailored to their needs.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["- حافظ على إطلاع عملائك الفرعيين في كل خطوة عبر تنبيهات وإشعارات مخصصة تلبي احتياجاتهم."])}
}