<template>
<avilableCars  data-aos="fade-up" data-aos-duration="2000" />
</template>

<script>
import avilableCars from "@/components/dashboard/avilableCarsPortal.vue";
import AOS from "aos";
export default{
  mounted() {
    AOS.init();
  },
  components:{
    avilableCars
  }
}
</script>
