<template>
<serviceDetails
       data-aos="fade-up" data-aos-duration="2000"
 />
 <downloadApp />
</template>

<script>
import serviceDetails from "@/components/layout/serviceDetails.vue";
import downloadApp from "@/components/layout/downloadApp.vue"

import AOS from "aos";

export default{
  mounted() {
    AOS.init();
  },
  components:{
serviceDetails,
downloadApp
  }
}
</script>
