import { createI18n } from "vue-i18n";

interface MessageSchema {
  [key: string]: any;
}

/**
 * Load locale messages
 */
function loadLocaleMessages(): MessageSchema {
  const locales = require.context(
    "../locales",
    true,
    /[A-Za-z0-9-_,\s]+\.json$/i
  );
  const messages: MessageSchema = {};
  locales.keys().forEach((key: string) => {
    const matched = key.match(/([A-Za-z0-9-_]+)\./i);
    if (matched && matched.length > 1) {
      const locale = matched[1];
      messages[locale] = locales(key).default;
    }
  });
  return messages;
}

export default createI18n({
  legacy: false,
  locale: localStorage.getItem("locale") || "en",
  fallbackLocale: localStorage.getItem("locale") || "en",
  messages: loadLocaleMessages(),
});
