<template>
  <section class="avialbe">
    <section class="about">
      <div class="about__header">
        <div class="about__line"></div>
        <h2 class="fw-bold">{{ $t("Our Services") }}</h2>
        <div class="about__line"></div>
      </div>
      <p class="about__subheader">
        {{ $t("What you get with logistics business") }}
      </p>
    </section>

    <!-- slider  -->

    <Carousel
      :autoplay="100"
      transition="2000"
      :items-to-show="4"
      :wrap-around="true"
      :breakpoints="breakpoints"
      v-if="cars.length > 0"
    >
      <Slide v-for="(slide, index) in cars" :key="index">
        <div v-if="slide.id == 3">
          <router-link to="/cars">
            <div class="slide-item">
              <div class="carousesl-image">
                <img :src="slide.image" alt="" />
              </div>
              <div class="slide-details">
                <h3 class="fw-bold car_name">
                  {{ slide.title }}
                </h3>
                <p class="car_desc">
                  {{ slide.desc }}
                </p>
              </div>
            </div>
          </router-link>
        </div>
        <div v-else>
          <router-link :to="'/serviceDetails/' + slide.id">
            <div class="slide-item">
              <div class="carousesl-image">
                <img :src="slide.image" alt="" />
              </div>
              <div class="slide-details">
                <h3 class="fw-bold car_name">
                  {{ slide.title }}
                </h3>
                <p class="car_desc">
                  {{ slide.desc }}
                </p>
              </div>
            </div>
          </router-link>
        </div>
      </Slide>
    </Carousel>

    <div v-else>
      <Message severity="info">{{ $t('No Services Yet !') }}</Message>
    </div>
  </section>
</template>

<script>
import { Carousel, Slide } from "vue3-carousel";
import Message from "primevue/message";

import "vue3-carousel/dist/carousel.css";
import image1 from "@/assets/imgs/1.png";
// import image2 from "@/assets/imgs/2.png";
import image3 from "@/assets/imgs/3.png";
import image4 from "@/assets/imgs/4.png";
export default {
  data() {
    return {
      breakpoints: {
        320: {
          itemsToShow: 1,
          snapAlign: "center",
        },
        // 700px and up
        700: {
          itemsToShow: 3.5,
          snapAlign: "center",
        },
        // 1024 and up
        1024: {
          itemsToShow: 4,
          snapAlign: "center",
        },
      },
      cars: [
        {
          id: "1",
          title: this.$t('comprehensive services'),
          desc: this.$t('Auto Auction Access For The Best US Auto Auctions'),
          image: image1,
        },
        // {
        //   title : 'Car Buying',
        //   desc:'Domestic Transportation',

        //   image : image2
        // },
        {
          id: 2,
          title: this.$t('Car Shipping'),
          desc: this.$t('Domestic Transportation'),
          image: image3,
        },
        {
          id: 3,
          title: this.$t('Car Selling'),
          desc: this.$t('Domestic Transportation'),
          image: image4,
        },
        {
          id: 4,
          title: this.$t('SubCustomers'),
          desc: this.$t('Sub-Customer Management Service by Noor Alwahdah'),
          image: image4,
        },
      ],
    };
  },
  name: "HeroSection",
  components: {
    Carousel,
    Slide,
    Message,
  },

  methods: {
    // async getCars(){
    //   await axios.get('available-cars')
    //   .then( (res)=>{
    //     this.cars = res.data.data.data ;
    //   } )
    // }/
  },
  mounted() {
    // this.getCars()
  },
};
</script>

<style>
.p-message {
  width: 60%;
  margin: auto;
  height: 50px;
}
.p-message-wrapper {
  display: flex;
  justify-content: space-around;
  align-items: center;
  height: 100%;
}
.carousel__item {
  min-height: 300px;
  width: 100%;
  background-color: var(--vc-clr-primary);
  color: var(--vc-clr-white);
  font-size: 20px;
  border-radius: 8px;
  display: flex;
  justify-content: center;
  overflow: visible;
  align-items: center;
}

.carousel__slide {
  padding: 10px;
  border-radius: 10px;
}

.carousel__prev,
.carousel__next {
  box-sizing: content-box;
  border: 5px solid white;
}
</style>

<style lang="scss" scoped>
.about__line {
  background-color: #efcf2d !important;
  font-size: 23px;
}
.slide-item {
  position: relative;
  width: 300px;
  height: 260px;
  border: 2px solid #efcf2d;
  border-radius: 20px;

  .carousesl-image {
    width: 300px;
    height: 100%;
    img {
      object-fit: cover;
      width: 300px;
      height: 100%;
      border-radius: 20px;
      filter: brightness(0.4);
    }
  }
  .car_desc {
    color: #fff;
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    text-align: start;
  }
  .slide-details {
    position: absolute;
    padding: 20px;
    bottom: 0;
    left: 0;
    .price {
      color: #ff0b0b;
      font-family: Poppins;
      font-size: 15px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
    }
  }
  .car_name {
    color: #efcf2d;
    text-align: start;
    font-family: Poppins;
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: 42px; /* 140% */
  }
}
.avialbe {
  background-image: url("@/assets/imgs/Image+Background.png"); // replace with actual path to background image
  background-size: cover;
  background-position: center;
  background-color: #383838b2;
  padding: 50px 20px 70px 0;
  color: white;
  text-align: center;

  .about {
    background-color: transparent;
    h2,
    p {
      color: #fff;
    }
  }
  &__content {
    background: rgba(0, 0, 0, 0.5);
    padding: 20px;
    border-radius: 10px;

    h1 {
      font-size: 2.5rem;
      margin-bottom: 20px;
    }

    p {
      font-size: 1.2rem;
    }
  }
}

.info {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 50px 20px;
  background-color: #f4f4f4;

  &__text {
    max-width: 60%;

    h2 {
      font-size: 2rem;
      margin-bottom: 10px;

      .highlight {
        color: #5a33aa; // Your primary color
        font-weight: bold;
      }
    }

    p {
      font-size: 1rem;
      color: #fff;
    }
  }

  &__image {
    max-width: 35%;

    img {
      width: 100%;
      height: auto;
      border-radius: 10px;
    }
  }
}
</style>
