<template>
  <div class="car-details">
    <div class="container">
      <nav class="mb-0 pb-0 px-0 py-0">
        <ul class="breadcrumb">
          <li>
            <router-link to="/ledger" class="main">{{ $t('Ledger') }}</router-link> /
            <span class="active">{{ $t('Car Details') }}</span>
          </li>
        </ul>
      </nav>

      <div
        class="car-info"
        v-motion-slide-visible-left
        :delay="400"
        :duration="1500"
      >
        <h5 class="text-start fw-bold mb-3">
          {{ car.brand }}, {{ car.model }}, {{ car.year }}
        </h5>
        <ul>
          <li><span>{{ $t('Car ID') }}:</span> <span class="color">{{ car.car_num }}</span></li>
          <li><span>{{ $t('LOT') }}:</span> <span class="color">{{ car.lot }}</span></li>
          <li>
            <span>{{ $t('Car Status') }}:</span>
            <span class="status-arrived color">{{ car.status }}</span>
          </li>
          <li><span>{{ $t('Vin') }}:</span> <span class="color">{{ car.vin }}</span></li>
          <li>
            <span>{{ $t('Auction Name') }}:</span> <span class="color">{{ car.auction }}</span>
          </li>
          <li>
            <span>{{ $t('Purchasing Date') }}:</span><span class="color"> {{ car.purchasing_date  || '======='}}</span>
          </li>
        </ul>
      </div>
      <!-- costs  -->
      <div
        class="costs"
        v-motion-slide-visible-right
        :delay="400"
        :duration="1500"
      >
        <h5 class="text-start fw-bold  px-4 py-3 mb-0">{{ $t('Car Amount') }}</h5>


        <table class="costs-table">
          <thead>
            <th class="main-bg">{{ $t('Costs Amount') }}</th>
            <th class="sec-bg">{{ $t('Paid Amount') }}</th>
          </thead>
          <tbody>
            <tr v-for="fin in finances" :key="fin.id">
              <td>
                <div class="d-flex justify-content-between">
                  <span>{{ fin.name }}</span>
                  <span class="color">{{ fin.subtotal_required }} AED</span>
                </div>
              </td>

              <td>
                <div class="d-flex justify-content-between">
                  <span>{{ $t('Paid') }}</span>
                  <span class="orange color">{{ fin.subtotal_paid }} AED</span>
                </div>
              </td>

            </tr>
           
          </tbody>
        </table>


      </div>

      <!-- total  -->


      <div
        class="car-info"
        v-motion-slide-visible-left
        :delay="400"
        :duration="1500"
      >
       
        <ul class="mb-0">
         
          <li>
            <span class="fw-bold">{{ $t('Total Costs') }}</span> <span class="color">{{ total_required  || '======='}}</span>
          </li>
          <li class="mb-0">
            <span class="fw-bold">{{ $t('Paid') }}</span><span class="color orange"> {{ total_paid  || '======='}}</span>
          </li>
        </ul>
      </div>




      <!-- outstanding  -->
         <section class="row mb-4">
        <div class="col-md-12">
            <section class="outstand w-100 d-flex justify-content-between align-items-baseline">
                <h6 class="mb-0">
                    {{ $t('Customer Outstanding') }}
                </h6>

                <div class="d-flex">
                    <!-- <span>
                        4000$
                    </span> -->

                    <!-- <span class="mx-3">
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                            <path d="M14.4297 5.92969L20.4997 11.9997L14.4297 18.0697" stroke="#322E2D" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
                            <path d="M3.5 12H20.33" stroke="#322E2D" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
                        </svg>
                    </span> -->

                    <span>
                        {{total_outstanding}} {{currency_code}}
                    </span>
                </div>
            </section>
        </div>
    </section>

      </div>


   


  </div>
</template>

<script>
import axios from "axios";
export default {
  name: "CarDetails",
  data() {
    return {
      car: {},
      finances : [],
      total_required : '',
      total_paid : '',
      total_outstanding : ''

    };
  },
  methods: {
    async getCarDetails() {
      await axios
        .get(`car-details/${this.$route.params.id}`, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        })
        .then((res) => {
          this.car = res.data.data;
        })
        .catch((err) => {
          console.error(err);
        });
    },

    // get car finance 

    async getCarFinance() {
      await axios
        .get(`car-finance/${this.$route.params.id}`, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        })
        .then((res) => {
          // this.car = res.data.data;
          this.finances = res.data.data.finances ;
          this.total_required = res.data.data.total_required ;
          this.total_paid = res.data.data.total_paid ;
          this.total_outstanding = res.data.data.total_outstanding ;
          
        })
        .catch((err) => {
          console.error(err);
        });
    },


  },
  mounted() {
    this.getCarDetails();
    this.getCarFinance();
  },
};
</script>

<style scoped lang="scss">
.costs-table{
  width: 100%;
  th{
    height: 50px;
    text-align: center;
font-family: Poppins;
font-size: 16px;
font-style: normal;
font-weight: 500;
line-height: normal;
letter-spacing: 0.07px;

  }
  .main-bg{
    background-color: #331F8E;
    color: #fff;
  }
  .sec-bg{
    background-color: #EFCF2D;
    color: #322E2D;
  }
  td{
    width: 50%;
    height: 50px;
    border: 1px solid #CFE7E8;
    padding-right: 20px;
    padding-left: 20px;
    div{width: 100%;}
  }
}


.car-details {
  margin: 0 auto;
  padding: 20px;
  background-color: #f7f8fc;
  border-radius: 8px;
}
.costs{padding: 0 !important;}
.car-info,
.costs,
.customer-paid {
  background-color: #fff;
  padding: 20px;
  border-radius: 8px;
  margin-bottom: 20px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  border: 1px solid rgba(219, 217, 217, 0.87);
  ul li {
    display: flex;
    justify-content: space-between;
    align-items: center;
    .color {
      font-weight: 600;
      color: #331f8e;
    }
    
  }
}
.orange {
      color: #f59e0b !important;
    }
.car-info ul,
.costs ul,
.customer-paid ul {
  list-style: none;
  padding: 0;
}

.car-info ul li,
.costs ul li,
.customer-paid ul li {
  margin-bottom: 10px;
}

.status-arrived {
  color: green !important;
}

.usd {
  color: #ffa500;
}

.aed {
  color: #6a1b9a;
}

.customer-outstanding {
  background-color: #fff;
  padding: 20px;
  border-radius: 8px;
  margin-bottom: 20px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  color: red;
  text-align: center;
  font-weight: bold;
}

.pay-button {
  display: block;
  width: 100%;
  padding: 15px;
  background-color: #6a1b9a;
  color: #fff;
  border: none;
  border-radius: 8px;
  text-align: center;
  font-size: 18px;
  cursor: pointer;
}

.pay-button:hover {
  background-color: #5e1789;
}
</style>
