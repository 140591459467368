<template>
<servicesComponent
       data-aos="fade-up" data-aos-duration="2000"
 />
</template>

<script>
import servicesComponent from "@/components/layout/servicesComponent.vue";
import AOS from "aos";

export default{
  mounted() {
    AOS.init();
  },
  components:{
servicesComponent
  }
}
</script>
