<template>
  <section class="customer-section">
    <div class="customer-block">
      <h2 class="fw-bold color">{{  $t('Do you want to ship the car?')  }}</h2>
      <p class="w-50 ">
        {{  $t('desc_1')  }}
      </p>
      <button class="contact-button ">
        
        <router-link to="/contact" class="mx-2 fw-bold">
          {{ $t('Ship') }}
        </router-link>
      </button>
    </div>
    <div
      class="customer-block"
    >
      <h2 class="fw-bold" style="color:#EFCF2D"> {{ $t('Do you want to buy a car?') }} </h2>
      <p class="w-50 ">
        {{ $t('desc_2') }}
      </p>
      <button class="contact-button " style="background-color:#EFCF2D">
       
        <router-link to="/cars" class="mx-2 fw-bold" style="color:#322E2D">
          {{ $t('Buy') }}
        </router-link>
      </button>
    </div>
  </section>
</template>

<script>
export default {
  data() {
    return {
      leftImage: require("@/assets/imgs/Image+Background.png"),
      rightImage: require("@/assets/imgs/Image+Background.png"),
    };
  },
};
</script>


<style >
.main-btn {
  background-color: #331f8e !important;
  color: #fff !important;
}
</style>
<style scoped>
.customer-section {
  display: flex;
  justify-content: space-between;
}

.customer-block {
  flex: 1;
  padding: 40px;
  color: white;
  background-size: cover;
  background-position: center;
}

.customer-block:nth-child(1) {
  background-color: #fff;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}

.customer-block:nth-child(2) {
  background-color: #fff;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}

h2 {
  font-size: 24px;
  margin-bottom: 20px;
}

p {
  font-size: 16px;
  margin-bottom: 20px;
}

.contact-button {
  background-color: #4d2e90;
  color: #fff;
  border: none;
  padding: 10px 20px;
  font-size: 16px;
  cursor: pointer;
  display: flex;
  align-items: center;
  width: 30%;
  justify-content: center;
  border-radius: 3px;
}

.contact-button font-awesome-icon {
  margin-right: 10px;
}
</style>
