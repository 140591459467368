<template>
  <div class="car-list-container pt-5">
    <div class="container">
      <ul class="breadcrumb">
        <li>
          <router-link to="/" class="main">{{ $t('Home') }}</router-link> /

          <span to="/dashboard" class="active">{{ $t('Avilable Cars') }}</span>
        </li>
      </ul>

      <header class="w-25 d-flex justify-content-start align-items-center">
        <h3 class="cat-title fw-bold my-cars-title">{{ $t('Avilable Cars') }}</h3>
      </header>

      <div class="car-list mb-4">
        <!-- <carComponentVue v-for="car in cars" :key="car.id" :car="car" :type="type"/> -->
        <div v-for="car in cars" :key="car.id">
          <router-link :to="'/carGuest/' + car.id" @click="storeCar(car)">
            <div class="car-item">
              <img :src="car.image" alt="Car Image" />
              <div class="car-info">
                <h2 class="car-model text-center color">
                  {{ car.brand }} , {{ car.model }}, {{ car.year }}
                </h2>
                <ul class="w-100">
                  <div class="row">
                    <div class="col-md-12 mb-3">
                      <div>
                        <li class="text-center">
                          <span class="text-center">{{ $t('Pirce') }}  </span>
                          <span class="text-center detail" style="color:#FF0B0B">{{
                            car.price 
                          }} AED</span>
                        </li>
                        <li class="text-center">
                          <span class="text-center">{{ $t('Car ID') }}  </span>
                          <span class="text-center detail">{{
                            car.car_num
                          }}</span>
                        </li>

                        

                        <li class="text-center">
                          <span class="text-center">{{ $t('Vin') }}  </span>
                          <span class="text-center detail">{{ car.vin }}</span>
                        </li>
                      </div>
                    </div>

                   
                  </div>
                </ul>
              </div>
              <div class="assign" v-if="type == 'Ready Collected'">
                <button
                  class="btn assing_btn px-5 pt-2 pb-2"
                  @click.stop="visible = true"
                >
                  {{ $t('Assign To') }}
                </button>
              </div>
            </div>
          </router-link>
        </div>
      </div>
      <!-- <footer class="mb-5" >
        <pagination :totalPages="totalPages" :currentPage="currentPage" @pageChanged="handlePageChange" />
      </footer> -->
    </div>
  </div>
</template>
  
  <script>
import axios from "axios";
//   import carComponentVue from '../shared/carComponent.vue';
// import Pagination from './PaginationComponent.vue';

export default {
  components: {
    // Pagination,
    //   carComponentVue
  },
  data() {
    return {
      cars: [],
      links: [
        { text: "Dashboard", url: "/dashboard" },
        { text: "New Cars", url: "/new-cars" },
        // ... Add more links as necessary
      ],
      currentPage: 1,
      totalPages: 10,
      outstanding: "",
      total_paid: "",
      total_required: "",
      currency_code: "",
    };
  },

  methods: {
    storeCar(car){
      console.log(car)
      localStorage.setItem('car', JSON.stringify(car)) ;
    },
    async getMyCars() {
      await axios
        .get("available-cars")
        .then((res) => {
          this.cars = res.data.data.data;
        })
        .catch((err) => {
          console.error(err);
        });
    },
   
  },
  mounted() {
    this.getMyCars();
    // let user = localStorage.getItem("user") || null;
    // if (user) {
    //   this.currency_code = JSON.parse(user).currency_code;
    // }
  },
};
</script>
  

  <style lang="scss">
.breadcrumb {
  a {
    text-decoration: none;
  }
  .main {
    color: #979797;
    font-family: Poppins;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px; /* 133.333% */
  }
  .active {
    color: #331f8e;
    font-family: Poppins;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: 16px; /* 133.333% */
  }
}
</style>
  <style scoped lang="scss">
.card-filter {
  border-radius: 10px;
  border: 1px solid rgba(219, 217, 217, 0.87);
  background: #fff;
}
.line {
  width: 50px;
  background-color: #331f8e;
  height: 3px;
  margin-top: -10px;
  &.right {
    margin-right: 15px;
  }
  &.left {
    margin-left: 15px;
  }
}
.detail {
  color: #331f8e;
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 109.5%;
}
.car-model {
  color: #331f8e;
  font-family: Poppins;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  text-align: start;
}
.cat-title {
  color: #331f8e;
  font-family: Poppins;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}
.car-list-container {
  padding: 5px;
  background-color: #f3f3ff;
}

header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

header h1 {
  color: #4d2e90;
}

nav ul {
  display: flex;
  list-style: none;
  gap: 10px;
}

nav ul li {
  font-size: 1.2em;
}

.car-list {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 20px;
  margin-top: 10px;
}

.car-item {
  height: auto;
  background-color: white;
  border-radius: 10px;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
  display: flex;
  gap: 20px;
  flex-direction:column;
}

.car-item img {
  width: 100%;
  height: 200px;
  border-radius: 10px;
}

.car-info {
    position: relative;
  display: flex;
  flex-direction: column;
  gap: 10px;
  width: 100%;
  padding: 10px;
  &::before{
    content:'';
    position:absolute;
    width: 25px;
    height:25px;
    background:#fff;
    right:50%;
    top: -31px;
    transform: rotate(134deg);

}
}

.car-info ul {
  list-style: none;
  padding: 0;
}

.car-info ul li {
  font-size: 1em;
  line-height: 1.5;
}

footer {
  display: flex;
  justify-content: center;
  margin-top: 20px;
}
</style>
  