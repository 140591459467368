<template>
  <div class="sub-info position-relative pb-5">
    <div class="container">
      <nav class="mb-0 pb-0 px-0 py-0 pt-4 mb-4">
        <ul class="breadcrumb">
          <li>
            <router-link to="/dashboard" class="main">{{ $t('Available cars') }}</router-link>
            /
           
            <router-link to="/dashboard" class="active">{{ $t('Car Details') }}</router-link>
          </li>
        </ul>
      </nav>

      <!-- sub customer info  -->
      <section v-motion-slide-visible-left :delay="400" :duration="1500">
        <ul class="nav nav-tabs" id="myTab" role="tablist">
          <li class="nav-item" role="presentation">
            <button
              class="nav-link active"
              id="home-tab"
              data-bs-toggle="tab"
              data-bs-target="#home"
              type="button"
              role="tab"
              aria-controls="home"
              aria-selected="true"
            >
              {{ $t('Car Details') }}
            </button>
          </li>
          <li class="nav-item mx-4" role="presentation">
            <button
              class="nav-link"
              id="profile-tab"
              data-bs-toggle="tab"
              data-bs-target="#profile"
              type="button"
              role="tab"
              aria-controls="profile"
              aria-selected="false"
              @click="getAssignedCars"
            >
              {{ $t('Car Tracker') }}
            </button>
          </li>
          <li class="nav-item mx-4" role="presentation">
            <button
              class="nav-link"
              id="finance-tab"
              data-bs-toggle="tab"
              data-bs-target="#finance"
              type="button"
              role="tab"
              aria-controls="finance"
              aria-selected="false"
              @click="getAssignedCars"
            >
              {{ $t('Car Finance') }}
            </button>
          </li>
          <li class="nav-item" role="presentation">
            <button
              class="nav-link"
              id="gallery-tab"
              data-bs-toggle="tab"
              data-bs-target="#gallery"
              type="button"
              role="tab"
              aria-controls="gallery"
              aria-selected="false"
              @click="getAssignedCars"
            >
              {{$t('Gallery')  }}
            </button>
          </li>
        </ul>
        <div class="tab-content mt-5" id="myTabContent">
            <!-- card details  -->
          <div
            class="tab-pane fade show active"
            id="home"
            role="tabpanel"
            aria-labelledby="home-tab"
          >
            <section class="cust-info">
              <!-- single  -->
              <div
                class="d-flex justify-content-between align-items-center mb-3"
              >
                <span class="text"> {{ $t('Car ID') }} </span>
                <span class="color">
                  {{ car.car_num }}
                </span>
              </div>
              <!-- single  -->
              <div
                class="d-flex justify-content-between align-items-center mb-3"
              >
                <span class="text"> {{ $t('Current Step') }} </span>
                <span class="color" style="color:#2DCA72">
                  {{ car.status }}
                </span>
              </div>
           
              <!-- single  -->
              <div
                class="d-flex justify-content-between align-items-center mb-3"
              >
                <span class="text"> {{ $t('Car Mark') }} </span>
                <span class="color">
                  {{ car.brand }}
                </span>
              </div>
           
              <!-- single  -->
              <div
                class="d-flex justify-content-between align-items-center mb-3"
              >
                <span class="text"> {{ $t('Car Model') }} </span>
                <span class="color">
                  {{ car.model }}
                </span>
              </div>
           
              <!-- single  -->
              <div
                class="d-flex justify-content-between align-items-center mb-3"
              >
                <span class="text"> {{ $t('Year') }} </span>
                <span class="color">
                  {{ car.year }}
                </span>
              </div>
           
              <!-- single  -->
              <div
                class="d-flex justify-content-between align-items-center mb-3"
              >
                <span class="text"> {{ $t("Transmission") }} </span>
                <span class="color">
                  {{ car.transmission_type }}
                </span>
              </div>
           
              <!-- single  -->
              <div
                class="d-flex justify-content-between align-items-center mb-3"
              >
                <span class="text"> {{ $t('Fuel') }} </span>
                <span class="color">
                  {{ car.fuel_type }}
                </span>
              </div>
           
              <!-- single  -->
              <div
                class="d-flex justify-content-between align-items-center mb-3"
              >
                <span class="text"> {{ $t('Vin') }} </span>
                <span class="color">
                  {{ car.vin }}
                </span>
              </div>
           
              <!-- single  -->
              <div
                class="d-flex justify-content-between align-items-center mb-3"
              >
                <span class="text"> {{ $t('Color') }} </span>
                <span class="color">
                  {{ car.color }}
                </span>
              </div>
           
              <!-- single  -->
              <div
                class="d-flex justify-content-between align-items-center mb-3"
              >
                <span class="text"> {{ $t('Body Type') }} </span>
                <span class="color">
                  {{ car.body_type }}
                </span>
              </div>
           
              <!-- single  -->
              <div
                class="d-flex justify-content-between align-items-center mb-3"
              >
                <span class="text"> {{ $t('Odometer') }} </span>
                <span class="color">
                  {{ car.distance }} KM
                </span>
              </div>
           
              <!-- single  -->
              <div
                class="d-flex justify-content-between align-items-center mb-3"
              >
                <span class="text"> {{  $t('Engine Type')}} </span>
                <span class="color">
                  {{ car.engine_type }}
                </span>
              </div>
           
              <!-- single  -->
              <div
                class="d-flex justify-content-between align-items-center mb-3"
              >
                <span class="text"> {{ $t('Cylinders') }} </span>
                <span class="color">
                  {{ car.engine_cylinder }}
                </span>
              </div>
           
              <!-- single  -->
              <div
                class="d-flex justify-content-between align-items-center mb-3"
              >
                <span class="text"> {{ $t('Auction Name') }} </span>
                <span class="color">
                  {{ car.auction }}
                </span>
              </div>
           
              <!-- single  -->
              <div
                class="d-flex justify-content-between align-items-center mb-3"
              >
                <span class="text"> {{ $t('Purchasing Date') }} </span>
                <span class="color">
                  {{ car.purchasing_date }}
                </span>
              </div>
           
              <!-- single  -->
              <div
                class="d-flex justify-content-between align-items-center mb-3"
              >
                <span class="text"> {{ $t('Key') }} </span>
                <span class="color">
                  {{ car.key }}
                </span>
              </div>
           
              <!-- single  -->
              <div
                class="d-flex justify-content-between align-items-center mb-3"
              >
                <span class="text"> {{ $t('Primary Damage') }} </span>
                <span class="color">
                  {{ car.damage }}
                </span>
              </div>
           
              <!-- single  -->
              <div
                class="d-flex justify-content-between align-items-center mb-3"
              >
                <span class="text"> {{ $t('Container') }} </span>
                <span class="color">
                  {{ car.container }}
                </span>
              </div>
           
              <!-- single  -->
              <div
                class="d-flex justify-content-between align-items-center mb-3"
              >
                <span class="text"> {{ $t('Estimation Arrival Date') }} </span>
                <span class="color">
                  {{ car.estimation_arrive_date }}
                </span>
              </div>
           
              <!-- single  -->
              <div
                class="d-flex justify-content-between align-items-center mb-3"
              >
                <span class="text"> {{ $t('Picked Up Location') }} </span>
                <span class="color">
                  {{ car.pickup_location }}
                </span>
              </div>
           
            </section>
          </div>

          <!-- car tracker  -->
          <div
            class="tab-pane fade"
            id="profile"
            role="tabpanel"
            aria-labelledby="profile-tab"
          >
            <div class="row mt-4">
              <div class="col-md-6 mb-3" v-for="(part, index) in parts" :key="index">
                <section class="history parts flex-column justify-content-between align-items-start">
                     <div
                        class="item d-flex"
                    >
                        <span v-if="part.car_status=='جديدة'||part.car_status=='new cars'" class="icon" style="background: #14b8a6"></span>
                        <span v-if="part.car_status=='تم سحبها'||part.car_status=='towing'" class="icon" style="background: #3b82f6"></span>
                        <span v-if="part.car_status=='تم تخزينها'||part.car_status=='warehouse'" class="icon" style="background: #6366f1"></span>
                        <span v-if="part.car_status=='تم شحنها'||part.car_status=='shipping'" class="icon" style="background: #facc15"></span>
                        <span v-if="part.car_status=='في الميناء'||part.car_status=='custom'" class="icon" style="background: #a855f7"></span>
                        <span v-if="part.car_status=='تم وصولها'||part.car_status=='shipping'" class="icon" style="background: #facc15"></span>
                        <span class="text mx-3" style="color: #322e2d">
                        {{part.car_status}}
                        </span>
                    </div>

                    <div class="d-flex justify-content-between align-items-center w-100 mt-3">
                        <div>
                            <div class="date text-start">
                                {{ $t('Start Date') }} 
                            </div>
                            <div class="color"> 
                                {{part.start_date}}
                            </div>
                        </div>
                        <div>
                            <div class="date text-start">
                                {{ $t('End Date') }} 
                            </div>
                            <div class="color"> 
                                {{part.end_date}}
                            </div>
                        </div>
                    </div>
                </section>
              </div>
            </div>
            <!-- <div v-else>
              <Message severity="info"
                >No History For This Car Yet !</Message
              >
            </div> -->
          </div>
          <!-- car finance  -->
          <div
            class="tab-pane fade "
            id="finance"
            role="tabpanel"
            aria-labelledby="finance-tab"
          >
          <!-- start car finance  -->
           <section class="car-finance">
            <div
        class="car-info"
        v-motion-slide-visible-left
        :delay="400"
        :duration="1500"
      >
        <h5 class="text-start fw-bold mb-3">
          {{ car.brand }}, {{ car.model }}, {{ car.year }}
        </h5>
        <ul>
          <li><span>{{ $t('Car ID') }}:</span> <span class="color">{{ car.car_num }}</span></li>
          <li><span>{{ $t('LOT') }}:</span> <span class="color">{{ car.lot }}</span></li>
          <li>
            <span>{{ $t('Car Status') }}:</span>
            <span class="status-arrived color">{{ car.status }}</span>
          </li>
          <li><span>{{ $t('Vin') }}:</span> <span class="color">{{ car.vin }}</span></li>
          <li>
            <span>{{ $t('Auction Name') }}:</span> <span class="color">{{ car.auction }}</span>
          </li>
          <li>
            <span>{{ $t('Purchasing Date') }}:</span><span class="color"> {{ car.purchasing_date  || '======='}}</span>
          </li>
        </ul>
      </div>
      <!-- costs  -->
      <div
        class="costs"
        v-motion-slide-visible-right
        :delay="400"
        :duration="1500"
      >
        <h5 class="text-start fw-bold  px-4 py-3 mb-0">{{ $t('Car Amount') }}</h5>


        <table class="costs-table">
          <thead>
            <th class="main-bg">{{ $t('Costs Amount') }}</th>
            <th class="sec-bg">{{ $t('Paid Amount') }}</th>
          </thead>
          <tbody>
            <tr v-for="fin in finances" :key="fin.id">
              <td>
                <div class="d-flex justify-content-between">
                  <span>{{ fin.name }}</span>
                  <span class="color">{{ fin.subtotal_required }} AED</span>
                </div>
              </td>

              <td>
                <div class="d-flex justify-content-between">
                  <span>{{ $t('Paid') }}</span>
                  <span class="orange color">{{ fin.subtotal_paid }} AED</span>
                </div>
              </td>

            </tr>
           
          </tbody>
        </table>


      </div>

      <!-- total  -->


      <div
        class="car-info"
        v-motion-slide-visible-left
        :delay="400"
        :duration="1500"
      >
       
        <ul class="mb-0">
         
          <li>
            <span class="fw-bold">{{ $t('Total Costs') }}</span> <span class="color">{{ total_required  || '======='}}</span>
          </li>
          <li class="mb-0">
            <span class="fw-bold">{{ $t('Paid') }}</span><span class="color orange"> {{ total_paid  || '======='}}</span>
          </li>
        </ul>
      </div>




      <!-- outstanding  -->
         <section class="row mb-4">
        <div class="col-md-12">
            <section class="outstand w-100 d-flex justify-content-between align-items-baseline">
                <h6 class="mb-0">
                    {{ $t('Customer Outstanding') }}
                </h6>

                <div class="d-flex">
                    <!-- <span>
                        4000$
                    </span> -->

                    <!-- <span class="mx-3">
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                            <path d="M14.4297 5.92969L20.4997 11.9997L14.4297 18.0697" stroke="#322E2D" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
                            <path d="M3.5 12H20.33" stroke="#322E2D" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
                        </svg>
                    </span> -->

                    <span>
                        {{total_outstanding}} {{currency_code}}
                    </span>
                </div>
            </section>
        </div>
    </section>
           </section>
          <!-- end car finance  -->
        </div>
          <!-- car Gallary  -->
          <div
            class="tab-pane fade"
            id="gallery"
            role="tabpanel"
            aria-labelledby="gallery-tab"
          >
            <div class="history parts flex-column justify-content-start align-items-start"  >
                <section v-for="(photo, index) in photos" :key="index" class="w-100">
                  <div
                        class="item d-flex mb-3"
                        style="width:240px"
                    >
                        <span  class="icon" style="background: #14b8a6"></span>
                        <span class="text mx-3" style="color: #322e2d">
                        {{ photo.car_status }}
                        </span>
                    </div>

                <div class="row w-100">
                    <div class="col-md-2 mb-3" v-for="(image, index) in photo.images" :key="index">
                        <div class="gallery-item">
                            <img :src="image.image"  alt="">
                        </div>
                    </div>
                </div>
                </section>
            </div>
          </div>
        </div>
      </section>
    </div>
  </div>

  <Toast />
</template>

<script>
import axios from "axios";
import Toast from "primevue/toast";

export default {
  data() {
    return {
      username: "",
      password: "",
      block: false,
      parts: [],
      car: {},
      reason: "",
      photos : [],
      is_blocked: null,
      finances : [],
      total_required : '',
      total_paid : '',
      total_outstanding : ''


    };
  },
  components: {
    Toast,
  },
  methods: {
    

    // get car finance 

    async getCarFinance() {
      await axios
        .get(`car-finance/${this.$route.params.id}`, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        })
        .then((res) => {
          // this.car = res.data.data;
          this.finances = res.data.data.finances ;
          this.total_required = res.data.data.total_required ;
          this.total_paid = res.data.data.total_paid ;
          this.total_outstanding = res.data.data.total_outstanding ;
          
        })
        .catch((err) => {
          console.error(err);
        });
    },
    async getCarDetails() {
      await axios
        .get(`car-details/${this.$route.params.id}`, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        })
        .then((res) => {
          this.car = res.data.data;
        
        })
        .catch((err) => {
          console.error(err);
        });
    },
    async getCarHistory() {
      await axios
        .get(`car-status-history/${this.$route.params.id}`, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        })
        .then((res) => {
          this.parts = res.data.data;
        
        })
        .catch((err) => {
          console.error(err);
        });
    },
    async getCarGallarey() {
      await axios
        .get(`car-gallery/${this.$route.params.id}`, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        })
        .then((res) => {
          this.photos = res.data.data ;
        
        })
        .catch((err) => {
          console.error(err);
        });
    },
  
  },
  mounted() {
    this.getCarDetails();
    this.getCarHistory();
    this.getCarGallarey();
    this.getCarFinance();
  },
};
</script>

<style scoped lang="scss">
.gallery-item{
    img{
        width: 160px;
        height: 160px;
        border-radius: 7px;
        object-fit: cover;
    }
}
.history{
    background-color: #fff;
    border-radius: 10px;
    border: 1px solid rgba(219, 217, 217, 0.87);
    padding: 20px;
}
.reason-text {
  color: #e50930;
  font-weight: bold;
}
.block-reason {
  color: #979797;
}
.main-btn.block {
  background-color: #e50930 !important;
}
.main-btn.Unblock {
  background-color: #322E2D !important;
}
.cust-info {
  background-color: #fff;
  border-radius: 10px;
  border: 1.3px solid rgba(219, 217, 217, 0.87);
  padding: 20px;
}
.sub-info {
  background-color: #f3f3ff;
}

.nav-tabs {
  display: flex;
  justify-content: center;
  border-bottom: none;
}
.nav-tabs .nav-link {
  font-weight: bold;
  border-radius: 7px;
  padding: 13px 12px;
  background: #fff;
  width: 200px;
  color: #979797;
}
.nav-tabs .nav-link.active {
  background: #34268f;
  color: #fff;
  margin: 0 15px;
}
</style>



<style scoped lang="scss">
.costs-table{
  width: 100%;
  th{
    height: 50px;
    text-align: center;
font-family: Poppins;
font-size: 16px;
font-style: normal;
font-weight: 500;
line-height: normal;
letter-spacing: 0.07px;

  }
  .main-bg{
    background-color: #331F8E;
    color: #fff;
  }
  .sec-bg{
    background-color: #EFCF2D;
    color: #322E2D;
  }
  td{
    width: 50%;
    height: 50px;
    border: 1px solid #CFE7E8;
    padding-right: 20px;
    padding-left: 20px;
    div{width: 100%;}
  }
}


.car-details {
  margin: 0 auto;
  padding: 20px;
  background-color: #f7f8fc;
  border-radius: 8px;
}
.costs{padding: 0 !important;}
.car-info,
.costs,
.customer-paid {
  background-color: #fff;
  padding: 20px;
  border-radius: 8px;
  margin-bottom: 20px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  border: 1px solid rgba(219, 217, 217, 0.87);
  ul li {
    display: flex;
    justify-content: space-between;
    align-items: center;
    .color {
      font-weight: 600;
      color: #331f8e;
    }
    
  }
}
.orange {
      color: #f59e0b !important;
    }
.car-info ul,
.costs ul,
.customer-paid ul {
  list-style: none;
  padding: 0;
}

.car-info ul li,
.costs ul li,
.customer-paid ul li {
  margin-bottom: 10px;
}

.status-arrived {
  color: green !important;
}

.usd {
  color: #ffa500;
}

.aed {
  color: #6a1b9a;
}

.customer-outstanding {
  background-color: #fff;
  padding: 20px;
  border-radius: 8px;
  margin-bottom: 20px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  color: red;
  text-align: center;
  font-weight: bold;
}

.pay-button {
  display: block;
  width: 100%;
  padding: 15px;
  background-color: #6a1b9a;
  color: #fff;
  border: none;
  border-radius: 8px;
  text-align: center;
  font-size: 18px;
  cursor: pointer;
}

.pay-button:hover {
  background-color: #5e1789;
}
</style>
