<template>
   <h2 class="text-center color fw-bold mt-4 fs-3">{{ $t('Get in Touch') }}</h2>
   <section class="info-container w-75 mt-5">
    <div class="row w-100">
        <div class="col-md-6 mb-3">
            <div class="single-info pb-3 pt-5">
                <div class="info-sign">
                    <img src="@/assets/imgs/Background.png" alt="">
                </div>
                <div class="mb-3">
                    <p class="mb-1">{{ $t('phone') }}</p>
                    <p class="color fw-bold text-center">
                        {{data.intro_phone}}
                    </p>
                </div>
                <div class="mb-3">
                    <p class="mb-1">{{ $t('WhatsApp') }}</p>
                    <p class="color fw-bold text-center">
                        {{  data.whatsapp_country_code  }} {{ data.whatsapp }}
                    </p>
                </div>
                
            </div>
        </div>
        <div class="col-md-6 mb-3">
            <div class="single-info pb-3 pt-5">
                <div class="info-sign">
                    <img src="@/assets/imgs/back.png" alt="">
                </div>
                <div class="mb-3">
                    <p class="mb-1">{{ $t('Email') }}</p>
                    <p class="color fw-bold text-center">
                        {{ data.email  }}
                    </p>
                </div>
                <div class="mb-3">
                    <p class="mb-1">{{ $t('Address') }}</p>
                    <p class="color fw-bold text-center">
                        {{data.intro_address}}
                    </p>
                </div>
                <!-- <div class="mb-3">
                    <p class="mb-1">Main</p>
                    <p class="color fw-bold text-center">
                        +966558441493
                    </p>
                </div> -->
            </div>
        </div>
    </div>
   </section>
</template>
  
  <script>
  import axios from "axios";
  export default{
      data(){
          return{
              data : {}
          }
      },
      methods:{
        async getSettings(){
      await axios.get('settings')
      .then( (res)=>{
        this.data = res.data.data
        console.log(this.data)
      } )
    },
      },
      mounted(){
          this.getSettings();
      }
  }
  </script>
  
  

  
  <style scoped lang="scss">
    .info-container{
        display:flex;
        justify-content:center;
        align-items:center;
        margin:auto;
    }
    .single-info{
        position:relative;
        border-radius: 20px;
        background: #F1F1F1;
        .info-sign{
            position:absolute;
            top:-35px;
            right:40%;
            
        }
    }
  </style>
  