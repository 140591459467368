<template>
  <div class="car-list-container pt-5">
    <div class="container mx-auto">
      <ul class="breadcrumb">
        <li>
          <router-link to="/" class="main">{{ $t('Home') }}</router-link> /
          <router-link to="/cars" class="main"> {{ $t('Available cars') }} </router-link> /

          <span to="/dashboard" class="active">{{ $t('Car Profile') }}</span>
        </li>
      </ul>

      <section class="details">
        <div class="row">
          <div class="col-md-6 mb-3">
            <section class="gallary" v-if="images.length > 0">
              <Carousel
                id="gallery"
                :items-to-show="1"
                :wrap-around="false"
                :autoplay="2000"
                v-model="currentSlide"
              >
                <Slide v-for="slide in images" :key="slide">
                  <div class="carousel__item">
                    <img :src="slide.image" alt="" />
                  </div>
                </Slide>
              </Carousel>

              <Carousel
                id="thumbnails"
                :items-to-show="4"
                :breakpoints="breakpoints"
                class="carousel_items"
                :wrap-around="true"
                v-model="currentSlide"
                ref="carousel"
                :autoplay="2000"
              >
                <Slide v-for="slide in images" :key="slide">
                  <div class="carousel__item" @click="slideTo(slide - 1)">
                    <img :src="slide.image" alt="" />
                  </div>
                </Slide>

                <template #addons>
                  <Navigation />
                  <Pagination />
                </template>
              </Carousel>
            </section>

            <div v-else>
              <Message severity="info"> {{ $t('No Images Provided') }} </Message>

            </div>


          </div>
          <div class="col-md-6 mb-3">
            <section class="car_details">
              <h2 class="color fw-bold fs-4 mx-3 mb-4">
                {{ car.year }} {{ car.model }} {{ car.brand }}
              </h2>
              <div class="mb-3">
                <div class="d-flex">
                  <span>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="35"
                      height="16"
                      viewBox="0 0 35 16"
                      fill="none"
                    >
                      <path
                        d="M26.6317 15.5696C24.906 15.5696 23.502 14.1756 23.502 12.4622C23.6736 8.33998 29.5904 8.34114 29.7614 12.4622C29.7614 14.1756 28.3575 15.5696 26.6317 15.5696ZM26.6317 10.8582C25.735 10.8582 25.0054 11.5778 25.0054 12.4622C25.0944 14.5899 28.1693 14.5893 28.258 12.4622C28.258 11.5778 27.5284 10.8582 26.6317 10.8582Z"
                        fill="#331F8E"
                      />
                      <path
                        d="M6.59258 15.5694C4.86683 15.5694 3.46289 14.1755 3.46289 12.4621C3.63449 8.33979 9.55135 8.34109 9.72233 12.4621C9.72233 14.1755 8.31832 15.5694 6.59258 15.5694ZM6.59258 10.8581C5.69584 10.8581 4.96633 11.5777 4.96633 12.4621C5.05538 14.5898 8.13019 14.5891 8.21889 12.4621C8.21889 11.5777 7.48931 10.8581 6.59258 10.8581Z"
                        fill="#331F8E"
                      />
                      <path
                        d="M23.5538 11.8705H9.66415C9.76283 12.3589 9.73242 12.9052 9.58398 13.3739H23.6339C23.4855 12.9052 23.4551 12.3588 23.5538 11.8705Z"
                        fill="#331F8E"
                      />
                      <path
                        d="M2.35831 5.13269H2.97876C4.95517 2.76204 7.92207 1.42617 11.062 1.50633V5.13269H12.5655V1.50633H16.7422C20.0108 1.50633 22.4066 3.72267 23.6 5.13269H25.0956C25.137 5.13269 25.1784 5.13611 25.2193 5.14295L25.5446 5.19721C23.7336 2.45295 20.1701 -0.100101 16.7422 0.00302098L10.7699 0.00295215C6.81458 -0.0239048 2.95361 2.11105 0.873527 5.47534L0.435547 6.12831C0.863208 5.52646 1.56545 5.13269 2.35831 5.13269Z"
                        fill="#331F8E"
                      />
                      <path
                        d="M34.0277 9.92056H32.3787C31.9635 9.92056 31.627 9.58399 31.627 9.16884C31.627 8.75368 31.9635 8.41711 32.3787 8.41711H34.0277C35.0245 8.45648 35.025 9.88092 34.0277 9.92056Z"
                        fill="#331F8E"
                      />
                      <path
                        d="M31.8447 6.24792L25.2191 5.14303C25.149 5.1081 2.45225 5.14665 2.35801 5.13277C1.05781 5.13277 0 6.19065 0 7.49092V12.6222C0 13.0374 0.336566 13.374 0.751721 13.374H3.60074C3.45238 12.9052 3.4219 12.3589 3.52058 11.8705H1.50344V7.49092C1.50344 7.01966 1.88682 6.63622 2.35815 6.63622H25.0333C25.0551 6.63956 31.6093 7.73346 31.6294 7.73592C32.1415 7.80603 32.5921 8.05656 32.9213 8.41718H34.0287C35.0259 8.45675 35.0255 9.88119 34.0287 9.92062H33.4858V11.8706H29.6988C29.7975 12.359 29.7671 12.9053 29.6186 13.374H34.2375C34.6526 13.374 34.9892 13.0375 34.9892 12.6223V9.86363C34.9892 8.05157 33.6381 6.4992 31.8447 6.24792Z"
                        fill="#331F8E"
                      />
                    </svg>
                  </span>

                  <div class="mx-3">Vin</div>
                </div>

                <div class="mx-4 color">
                  {{ car.vin }}
                </div>
              </div>
              <div class="mb-3">
                <div class="d-flex">
                  <span>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="35"
                      height="16"
                      viewBox="0 0 35 16"
                      fill="none"
                    >
                      <path
                        d="M26.6317 15.5696C24.906 15.5696 23.502 14.1756 23.502 12.4622C23.6736 8.33998 29.5904 8.34114 29.7614 12.4622C29.7614 14.1756 28.3575 15.5696 26.6317 15.5696ZM26.6317 10.8582C25.735 10.8582 25.0054 11.5778 25.0054 12.4622C25.0944 14.5899 28.1693 14.5893 28.258 12.4622C28.258 11.5778 27.5284 10.8582 26.6317 10.8582Z"
                        fill="#331F8E"
                      />
                      <path
                        d="M6.59258 15.5694C4.86683 15.5694 3.46289 14.1755 3.46289 12.4621C3.63449 8.33979 9.55135 8.34109 9.72233 12.4621C9.72233 14.1755 8.31832 15.5694 6.59258 15.5694ZM6.59258 10.8581C5.69584 10.8581 4.96633 11.5777 4.96633 12.4621C5.05538 14.5898 8.13019 14.5891 8.21889 12.4621C8.21889 11.5777 7.48931 10.8581 6.59258 10.8581Z"
                        fill="#331F8E"
                      />
                      <path
                        d="M23.5538 11.8705H9.66415C9.76283 12.3589 9.73242 12.9052 9.58398 13.3739H23.6339C23.4855 12.9052 23.4551 12.3588 23.5538 11.8705Z"
                        fill="#331F8E"
                      />
                      <path
                        d="M2.35831 5.13269H2.97876C4.95517 2.76204 7.92207 1.42617 11.062 1.50633V5.13269H12.5655V1.50633H16.7422C20.0108 1.50633 22.4066 3.72267 23.6 5.13269H25.0956C25.137 5.13269 25.1784 5.13611 25.2193 5.14295L25.5446 5.19721C23.7336 2.45295 20.1701 -0.100101 16.7422 0.00302098L10.7699 0.00295215C6.81458 -0.0239048 2.95361 2.11105 0.873527 5.47534L0.435547 6.12831C0.863208 5.52646 1.56545 5.13269 2.35831 5.13269Z"
                        fill="#331F8E"
                      />
                      <path
                        d="M34.0277 9.92056H32.3787C31.9635 9.92056 31.627 9.58399 31.627 9.16884C31.627 8.75368 31.9635 8.41711 32.3787 8.41711H34.0277C35.0245 8.45648 35.025 9.88092 34.0277 9.92056Z"
                        fill="#331F8E"
                      />
                      <path
                        d="M31.8447 6.24792L25.2191 5.14303C25.149 5.1081 2.45225 5.14665 2.35801 5.13277C1.05781 5.13277 0 6.19065 0 7.49092V12.6222C0 13.0374 0.336566 13.374 0.751721 13.374H3.60074C3.45238 12.9052 3.4219 12.3589 3.52058 11.8705H1.50344V7.49092C1.50344 7.01966 1.88682 6.63622 2.35815 6.63622H25.0333C25.0551 6.63956 31.6093 7.73346 31.6294 7.73592C32.1415 7.80603 32.5921 8.05656 32.9213 8.41718H34.0287C35.0259 8.45675 35.0255 9.88119 34.0287 9.92062H33.4858V11.8706H29.6988C29.7975 12.359 29.7671 12.9053 29.6186 13.374H34.2375C34.6526 13.374 34.9892 13.0375 34.9892 12.6223V9.86363C34.9892 8.05157 33.6381 6.4992 31.8447 6.24792Z"
                        fill="#331F8E"
                      />
                    </svg>
                  </span>

                  <div class="mx-3">{{ $t('Lot No') }}.</div>
                </div>

                <div class="mx-4 color">
                  {{ car.lot }}
                </div>
              </div>
              <div class="mb-3">
                <div class="d-flex">
                  <span>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="29"
                      height="25"
                      viewBox="0 0 29 25"
                      fill="none"
                    >
                      <path
                        d="M28.9693 14.1123C28.9692 14.1121 28.9692 14.1121 28.9692 14.1119L23.492 0.3591C23.3757 0.0668887 23.0445 -0.0757646 22.7523 0.0404793C22.7521 0.0405505 22.7519 0.0406217 22.7516 0.0407641L0.53132 8.88869C0.440702 8.92784 0.362115 8.99034 0.30353 9.06978C0.118735 9.16581 0.00199316 9.35587 0 9.56409V24.3704C0 24.6849 0.254982 24.9399 0.569474 24.9399H24.4874C24.8019 24.9399 25.0569 24.6849 25.0569 24.3704V16.2839L28.6514 14.8522C28.9435 14.7357 29.0858 14.4044 28.9693 14.1123ZM23.9179 23.8009H1.13895V10.1336H23.9179V23.8009ZM9.50168 8.99746L19.037 5.19793C19.888 6.09243 21.0671 6.60083 22.3018 6.60567L23.2528 8.99746H9.50168ZM25.0569 15.0607V9.56409C25.0569 9.24959 24.8019 8.99461 24.4874 8.99461H24.4783L23.2716 5.96387C23.2582 5.9386 23.2426 5.91454 23.2249 5.89211C23.1638 5.61784 22.9098 5.4307 22.6298 5.45362C21.481 5.55833 20.3572 5.07442 19.644 4.16775C19.4666 3.94871 19.1546 3.89426 18.9134 4.04018C18.8863 4.04339 18.8595 4.04837 18.8331 4.05499L7.19189 8.69051C7.05102 8.74575 6.93833 8.8553 6.87925 8.99461H3.34395L22.6446 1.30955L27.7009 14.006L25.0569 15.0607Z"
                        fill="#331F8E"
                      />
                      <path
                        d="M2.68395 19.9564C3.78958 20.2832 4.65476 21.1473 4.98292 22.2525C5.05488 22.4937 5.27677 22.659 5.52847 22.6591C5.55801 22.6573 5.58734 22.6528 5.61617 22.646C5.64173 22.6533 5.66785 22.6586 5.69419 22.6619H19.3616C19.3851 22.6589 19.4083 22.6541 19.4311 22.6477C19.7156 22.7169 20.0023 22.5424 20.0716 22.2579C20.0718 22.2568 20.0721 22.2558 20.0723 22.2548C20.3996 21.1484 21.2649 20.2831 22.3713 19.9558C22.6395 19.8738 22.8063 19.6064 22.7619 19.3294C22.7695 19.3018 22.775 19.2736 22.7784 19.2451V14.6893C22.7751 14.6608 22.7694 14.6326 22.7613 14.605C22.806 14.3279 22.6392 14.0604 22.3707 13.9786C21.2643 13.6514 20.3992 12.7857 20.0729 11.6791C19.9904 11.4089 19.7204 11.2418 19.4419 11.2884C19.4155 11.2811 19.3887 11.2757 19.3616 11.2725H5.69419C5.6655 11.2759 5.6371 11.2816 5.60934 11.2895C5.33236 11.2454 5.06514 11.4121 4.98292 11.6802C4.6554 12.7863 3.79015 13.6514 2.68395 13.9786C2.41565 14.0606 2.24894 14.328 2.29329 14.605C2.28589 14.6327 2.28055 14.6608 2.27734 14.6893V19.2451C2.28062 19.2719 2.28596 19.2983 2.29329 19.3243C2.24659 19.6031 2.41366 19.8734 2.68395 19.9564ZM3.41629 14.9205C4.5598 14.4612 5.46612 13.5549 5.9254 12.4114H19.1298C19.5893 13.5549 20.4958 14.4612 21.6395 14.9205V19.0139C20.4963 19.4737 19.5902 20.3799 19.1304 21.523H5.9254C5.46562 20.3799 4.55944 19.4737 3.41629 19.0139V14.9205Z"
                        fill="#331F8E"
                      />
                      <path
                        d="M12.5282 20.384C14.4153 20.384 15.945 18.8542 15.945 16.9671C15.945 15.08 14.4153 13.5503 12.5282 13.5503C10.6411 13.5503 9.11133 15.08 9.11133 16.9671C9.11318 18.8535 10.6419 20.3821 12.5282 20.384ZM12.5282 14.6892C13.7862 14.6892 14.8061 15.7091 14.8061 16.9671C14.8061 18.2252 13.7862 19.245 12.5282 19.245C11.2701 19.245 10.2503 18.2252 10.2503 16.9671C10.2503 15.7091 11.2701 14.6892 12.5282 14.6892Z"
                        fill="#331F8E"
                      />
                      <path
                        d="M6.26437 17.8215C6.73611 17.8215 7.11858 17.439 7.11858 16.9672C7.11858 16.4955 6.73611 16.113 6.26437 16.113C5.79263 16.113 5.41016 16.4955 5.41016 16.9672C5.41016 17.4391 5.79263 17.8215 6.26437 17.8215ZM6.26437 16.6825C6.42161 16.6825 6.54911 16.81 6.54911 16.9672C6.54911 17.1245 6.42161 17.252 6.26437 17.252C6.10712 17.252 5.97963 17.1245 5.97963 16.9672C5.97963 16.81 6.10712 16.6825 6.26437 16.6825Z"
                        fill="#331F8E"
                      />
                      <path
                        d="M18.7917 17.8215C19.2634 17.8215 19.6459 17.439 19.6459 16.9672C19.6459 16.4955 19.2634 16.113 18.7917 16.113C18.32 16.113 17.9375 16.4955 17.9375 16.9672C17.9375 17.4391 18.32 17.8215 18.7917 17.8215ZM18.7917 16.6825C18.949 16.6825 19.0764 16.81 19.0764 16.9672C19.0764 17.1245 18.949 17.252 18.7917 17.252C18.6345 17.252 18.507 17.1245 18.507 16.9672C18.507 16.81 18.6345 16.6825 18.7917 16.6825Z"
                        fill="#331F8E"
                      />
                    </svg>
                  </span>

                  <div class="mx-3">{{  $t('Price')  }}</div>
                </div>

                <div class="mx-4 color">{{ car.price }} AED</div>
              </div>
            </section>
          </div>
        </div>
      </section>
    </div>
  </div>
</template>

<script>
import { Carousel, Slide, Navigation, Pagination } from "vue3-carousel";

import "vue3-carousel/dist/carousel.css";
import Message from 'primevue/message';

export default {
  data() {
    return {
      car: {},
      images: [],
      currentSlide: 0,
      breakpoints: {
        320:{
          itemsToShow: 2,
          snapAlign: 'center',
        },
        // 700px and up
        700: {
          itemsToShow: 2,
          snapAlign: 'center',
        },
        // 1024 and up
        1024: {
          itemsToShow: 4,
          snapAlign: 'start',
        },
      },
    };
  },
  components: {
    Carousel,
    Slide,
    Navigation,
    Pagination,
    Message
  },
  methods: {
    slideTo(val) {
      this.currentSlide = val;
    },
  },
  mounted() {
    let car = localStorage.getItem("car");
    if (car) {
      this.car = JSON.parse(car);
      if (JSON.parse(car).gallery.length > 0) {
        this.images = JSON.parse(car).gallery[0].images;
      }
    } 
  },
};
</script>

<style scoped>
.car_details {
  background: #fff;
  border-radius: 11px;
  padding: 25px;
}
.color {
  font-weight: 500;
  margin-top: 12px !important;
}
.carousel__viewport .carousel__item img {
  width: 100% !important;
  border-radius: 9px !important;
  object-fit: cover;
}
.carousel_items .carousel__item img {
  width: 141px;
  height: 150px;
  border-radius: 10px;
  object-fit: cover;
}
</style>

<style>
.carousel_items .carousel__item {
  min-height: 150px !important;
}
</style>
