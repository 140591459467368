<template>
  <div class="car-list-container pt-5">
    <div class="container w-75 mx-auto">
      <ul class="breadcrumb">
        <li>
          <router-link to="/" class="main">{{ $t('Home') }}</router-link> /

          <span to="/dashboard" class="active">{{ $t('Services') }}</span>
        </li>
      </ul>

      <header class=" d-flex justify-content-center align-items-center">
        <h2 class="cat-title fw-bold my-cars-title text-center fs-3">{{ $t('Services') }}</h2>
      </header>

      <div class="mt-5 mb-5">
        <div class="row mb-5">
             <div class="col-md-5">
            <div class="about-image">
              <img
                :src="require('@/assets/imgs/1.png')"
                alt=""
                class="w-100"
              />
            </div>
          </div>

          <div class="col-md-7">
            <h3 class="color text-start">
              {{ $t('comprehensive services') }}
                        </h3>
            <p  class="text-start desc">
{{ $t('ser_1') }}
</p>

            <div class="read-more yellow fw-bold fs-5 text-start">
                <router-link to="/serviceDetails/1">
                {{ $t('READ MORE') }}
                </router-link>
            </div>
          </div>
         
        </div>




        <div class="row mb-5">
            

           

          <div class="col-md-7">
            <h3 class="color text-start">
                {{ $t('Car Shipping') }}
            </h3>
            <p  class="text-start desc">
              <strong>{{ $t('Shipping your autos throughout the USA') }}</strong>, {{ $t('ser_2') }}            </p>

            <div class="read-more yellow fw-bold fs-5 text-start">
                 <router-link to="/serviceDetails/2">
                {{ $t('READ MORE') }}
                </router-link>
            </div>
          </div>

          <div class="col-md-5">
            <div class="about-image">
              <img
                :src="require('@/assets/imgs/3.png')"
                alt=""
                class="w-100"
              />
            </div>
          </div>


          
         
        </div>


         <div class="row mb-5">
            

            
           <div class="col-md-5">
            <div class="about-image">
              <img
                :src="require('@/assets/imgs/4.png')"
                alt=""
                class="w-100"
              />
            </div>
          </div>


          <div class="col-md-7">
            <h3 class="color text-start">
                {{ $t('Car selling') }}
            </h3>
            <p  class="text-start desc">
              <strong>{{ $t('Shipping your autos throughout the USA') }}</strong>, {{ $t('ser_2') }}            </p>


            <div class="read-more yellow fw-bold fs-5 text-start">
                 <router-link to="/cars">
                {{ $t('READ MORE') }}
                </router-link>
            </div>
          </div>




          
         
        </div>


          <div class="row mb-5">
          
            

          <div class="col-md-7">
            <h3 class="color text-start">
                {{ $t('SubCustomers') }}
            </h3>
            <p  class="text-start desc">
              {{  $t('sub_4')  }}
              </p>
            <div class="read-more yellow fw-bold fs-5 text-start">
                <router-link to="/serviceDetails/4">
                {{ $t('READ MORE') }}
                </router-link>
            </div>
          </div>


            
          <div class="col-md-5">
            <div class="about-image">
              <img
                :src="require('@/assets/imgs/5.png')"
                alt=""
                class="w-100"
              />
            </div>
          </div>

        


          
         
        </div>

        



      </div>
    </div>
  </div>
</template>

<script >

export default {
  data() {
    return {
      data: "",
      faqs: [],
    };
  },
  components: {},
};
</script>


<style  lang="scss" scoped>
.desc{
    line-height: 25px;
    color: #322E2D;
    font-size: 15px;
}
.about-image{
    width: 400px;
    height: 210px;
    border-radius: 20px;
    img{
        width: 100%;
        height:100%;
        object-fit: cover;
        border-radius: 20px;
    }
}
.apexcharts-legend {
  display: none !important;
}
.parts {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  .item {
    width: 200px;
    .icon {
      width: 20px;
      height: 20px;
    }
    .text {
      font-weight: 500;
    }
  }
}
</style>

<style scoped lang="scss">
.card-filter {
  border-radius: 10px;
  border: 1px solid rgba(219, 217, 217, 0.87);
  background: #fff;
}
.line {
  width: 50px;
  background-color: #331f8e;
  height: 3px;
  margin-top: -10px;
  &.right {
    margin-right: 15px;
  }
  &.left {
    margin-left: 15px;
  }
}
.detail {
  color: #331f8e;
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 109.5%;
}
.car-model {
  color: #322e2d;
  font-family: Poppins;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  text-align: start;
}
.cat-title {
  color: #331f8e;
  font-family: Poppins;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}
.car-list-container {
  padding: 5px;
  background-color: #f3f3ff;
}

header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

header h1 {
  color: #4d2e90;
}

nav ul {
  display: flex;
  list-style: none;
  gap: 10px;
}

nav ul li {
  font-size: 1.2em;
}

.car-list {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 20px;
  margin-top: 10px;
}

.car-item {
  height: 230px;
  background-color: white;
  padding: 10px;
  border-radius: 10px;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
  display: flex;
  gap: 20px;
}

.car-item img {
  width: 150px;
  height: 200px;
  border-radius: 10px;
}

.car-info {
  display: flex;
  flex-direction: column;
  gap: 10px;
  width: 50%;
}

.car-info ul {
  list-style: none;
  padding: 0;
}

.car-info ul li {
  font-size: 1em;
  line-height: 1.5;
}

footer {
  display: flex;
  justify-content: center;
  margin-top: 20px;
}
</style>
